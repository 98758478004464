export const workContent = [
//project start
{
  shortTitle: "test",
  title: "Bilderbuch | Softpower Tour 2024",
  slug: "bilderbuch-softpower-live-2024",
  imageURL:
    "https://lichtgst.uber.space/photos/projects/bilderbuch-softpower-live-2024/lichtgestalten-bilderbuch-live-2024-12.jpg",
  imageTitle: "Bilderbuch Softpower Tour 2024 | LED wall mapping and operating with Resolume and TouchDesigner",
  galleryContent:[
    {
      type: "image",
      url: "https://lichtgst.uber.space/photos/projects/bilderbuch-softpower-live-2024/lichtgestalten-bilderbuch-live-2024-12.jpg",
      altEn: "Bilderbuch Softpower Tour 2024 | LED wall mapping and operating with Resolume and TouchDesigner",
      altDe: "Bilderbuch Softpower Tour 2024 | Bühne mit LED-Wand, Visuals mit Resolume and TouchDesigner",
    },
    {
      type: "image",
      url: "https://lichtgst.uber.space/photos/projects/bilderbuch-softpower-live-2024/lichtgestalten-bilderbuch-live-2024-1.jpg",
      altEn: "Bilderbuch Softpower Tour 2024 | LED wall stage design",
      altDe: "Bilderbuch Softpower Tour 2024 | Bühnendesign mit LED-Wand",
    },
    {
      type: "image",
      url: "https://lichtgst.uber.space/photos/projects/bilderbuch-softpower-live-2024/lichtgestalten-bilderbuch-live-2024-7.jpg",
      altEn: "Bilderbuch Softpower Tour 2024 | Realtime face tracking with TouchDesigner",
      altDe: "Bilderbuch Softpower Tour 2024 | Echtzeit-FaceTracking mit TouchDesigner",
    },
    {
      type: "image",
      url: "https://lichtgst.uber.space/photos/projects/bilderbuch-softpower-live-2024/lichtgestalten-bilderbuch-live-2024-11.jpg",
      altEn: "Bilderbuch Softpower Tour 2024 | LED wall mapping and operating with Resolume and TouchDesigner",
      altDe: "Bilderbuch Softpower Tour 2024 | Bühne mit LED-Wand, Visuals mit Resolume and TouchDesigner",
    },
    {
      type: "image",
      url: "https://lichtgst.uber.space/photos/projects/bilderbuch-softpower-live-2024/lichtgestalten-bilderbuch-live-2024-2.jpg",
      altEn: "Bilderbuch Softpower Tour 2024 | LED wall mapping and operating with Resolume and TouchDesigner",
      altDe: "Bilderbuch Softpower Tour 2024 | Bühne mit LED-Wand, Visuals mit Resolume and TouchDesigner",
    },
    {
      type: "image",
      url: "https://lichtgst.uber.space/photos/projects/bilderbuch-softpower-live-2024/lichtgestalten-bilderbuch-live-2024-3.jpg",
      altEn: "Bilderbuch Softpower Tour 2024 | Arena Wien",
      altDe: "Bilderbuch Softpower Tour 2024 | Arena Wien",
    },
    {
      type: "image",
      url: "https://lichtgst.uber.space/photos/projects/bilderbuch-softpower-live-2024/lichtgestalten-bilderbuch-live-2024-4.jpg",
      altEn: "Bilderbuch Softpower Tour 2024 | LED wall mapping and operating with Resolume and TouchDesigner",
      altDe: "Bilderbuch Softpower Tour 2024 | Bühne mit LED-Wand, Visuals mit Resolume and TouchDesigner",
    },
    {
      type: "image",
      url: "https://lichtgst.uber.space/photos/projects/bilderbuch-softpower-live-2024/lichtgestalten-bilderbuch-live-2024-8.jpg",
      altEn: "Bilderbuch Softpower Tour 2024 | LED wall mapping and operating with Resolume and TouchDesigner",
      altDe: "Bilderbuch Softpower Tour 2024 | Bühne mit LED-Wand, Visuals mit Resolume and TouchDesigner",
    },
    {
      type: "image",
      url: "https://lichtgst.uber.space/photos/projects/bilderbuch-softpower-live-2024/lichtgestalten-bilderbuch-live-2024-5.jpg",
      altEn: "Bilderbuch Softpower Tour 2024 | LED wall mapping and operating with Resolume and TouchDesigner",
      altDe: "Bilderbuch Softpower Tour 2024 | Bühne mit LED-Wand, Visuals mit Resolume and TouchDesigner",
    },
    {
      type: "image",
      url: "https://lichtgst.uber.space/photos/projects/bilderbuch-softpower-live-2024/lichtgestalten-bilderbuch-live-2024-6.jpg",
      altEn: "Bilderbuch Softpower Tour 2024 | LED wall mapping and operating with Resolume and TouchDesigner",
      altDe: "Bilderbuch Softpower Tour 2024 | Bühne mit LED-Wand, Visuals mit Resolume and TouchDesigner",
    },
  ],
  descriptionEn:
    'For their “Softpower 2024”, Bilderbuch decided to remove nearly all lighting from the stage and replace it by LED screens. Resembling modern virtual production studios, this LED setup enables a versatile use of video content. Thus the band can be staged in CGI landscapes, fake light settings or abstract motion graphics. We were responsible for the programming of the show, additional motion graphics, the integration of 5 small scale cameras and realtime video effects like face tracking of the band members. Made with Resolume and TouchDesigner.',
  descriptionDe:
    'Für die „Softpower 2024“-Tour verzichtet Bilderbuch nahezu vollständig auf Bühnenlichter. Stattdessen werden LED-Displays eingesetzt, deren Aufbau Virtual Production Studios ähnelt. Dadurch kann die Band zum Beispiel in CGI-Landschaften, künstlichen Lichtsetups oder abstrakten Motion Graphics inszeniert werden. Wir waren für die Programmierung der Show verantwortlich, animierten Teile der Motion Graphics und kümmerten uns um die Integration von fünf Kameras für Echtzeit-Videoeffekte wie z.B. das Face-Tracking der Bandmitglieder.',
  info: [
    {
      slugCreditsEn: "Date",
      slugCreditsDe: "Datum",
      values: ["22.03.2024 - ongoing"],
    },
    {
      slugCreditsEn: "Client",
      slugCreditsDe: "Kunde",
      values: [
        "Bilderbuch #https://bilderbuch-musik.at/home",
      ],
    },
    {
      slugCreditsEn: "Creative Direction",
      slugCreditsDe: "Creative Direction",
      values: [
        "Studio Clemens Loeffelholz #https://clemensloeffelholz.com/",
        "TOR Studio #https://torstudio.net/",
        "Maurice Ernst",
      ],
    },
    {
      slugCreditsEn: "Production Design",
      slugCreditsDe: "Production Design",
      values: [
        "Studio Clemens Loeffelholz #https://clemensloeffelholz.com/",
        "TOR Studio #https://torstudio.net/",
      ],
    },
    {
      slugCreditsEn: "Lighting Design & Lighting Direction",
      slugCreditsDe: "Lighting Design & Lighting Direction",
      values: [
        "Studio Clemens Loeffelholz #https://clemensloeffelholz.com/",
      ],
    },
    {
      slugCreditsEn: "Motion Design",
      slugCreditsDe: "Motion Design",
      values: [
        "TOR Studio #https://torstudio.net/",
        "lichtgestalten",
      ],
    },
    {
      slugCreditsEn: "Live Video Direction",
      slugCreditsDe: "Live Video Direction",
      values: [
        "lichtgestalten",
      ],
    },
    {
      slugCreditsEn: "Media Server Setup",
      slugCreditsDe: "Media Server Setup",
      values: [
        "lichtgestalten",
      ],
    },
    {
      slugCreditsEn: "Photos / Video",
      slugCreditsDe: "Fotos / Video",
      values: [
        "Pascal Schattenburg",
        "Paul Gärtner #https://www.paulgärtner.com/"
      ],
    },
  ],
  tags: ["animation", "realtime"],
},
  //project start
{
  shortTitle: "test",
  title: "Innellea | The Belonging World Tour 2024",
  slug: "innellea-tour-2024",
  imageURL:
    "https://lichtgst.uber.space/photos/projects/innellea-live-2024/innellea-live-2024-lichtgestalten-11.jpg",
  imageTitle: "Innellea The Belonging Tour 2024 | Live visuals and video operating",
  galleryContent:[
    {
      type: "image",
      url: "https://lichtgst.uber.space/photos/projects/innellea-live-2024/innellea-live-2024-lichtgestalten-11.jpg",
      altEn: "Innellea The Belonging Tour 2024 | TouchDesigner glass shader in Cairo",
      altDe: "Innellea The Belonging Tour 2024 | TouchDesigner Glass shader in Kairo",
    },
    {
      type: "loop",
      url: "https://lichtgst.uber.space/videoLoops/projects/innellea-live-2024/innellea-live-2024-lichtgestalten-antwerp-2.mp4",
      placeholder: "https://lichtgst.uber.space/photos/projects/rin-live-2023/rin-live-2023-lichtgestalten-2.jpg",
      altEn: "Innellea The Belonging Tour 2024 | Video of a TouchDesigner glass shader in Antwerp",
      altDe: "Innellea The Belonging Tour 2024 | Video eines TouchDesigner glass shaders in Antwerpen",
    },
    {
      type: "image",
      url: "https://lichtgst.uber.space/photos/projects/innellea-live-2024/innellea-live-2024-lichtgestalten-13.jpg",
      altEn: "Innellea The Belonging Tour 2024 | TouchDesigner glass shader in Riyadh",
      altDe: "Innellea The Belonging Tour 2024 | TouchDesigner Glass shader in Riad",
    },
    {
      type: "loop",
      url: "https://lichtgst.uber.space/videoLoops/projects/innellea-live-2024/innellea-live-2024-lichtgestalten-paris-3.mp4",
      placeholder: "https://lichtgst.uber.space/photos/projects/rin-live-2023/rin-live-2023-lichtgestalten-2.jpg",
      altEn: "Innellea The Belonging Tour 2024 | Tornado created with Unreal Engine shown in Paris",
      altDe: "Innellea The Belonging Tour 2024 | Mit der Unreal Engine erstellter Tornado in Paris",
    },
    {
      type: "image",
      url: "https://lichtgst.uber.space/photos/projects/innellea-live-2024/innellea-live-2024-lichtgestalten-3.jpg",
      altEn: "Innellea The Belonging Tour 2024 | Tornado created with Unreal Engine",
      altDe: "Innellea The Belonging Tour 2024 | Mit der Unreal Engine erstellter Tornado",
    },
    {
      type: "image",
      url: "https://lichtgst.uber.space/photos/projects/innellea-live-2024/innellea-live-2024-lichtgestalten-8.jpg",
      altEn: "Innellea The Belonging Tour 2024 | Water scene created with Unreal Engine",
      altDe: "Innellea The Belonging Tour 2024 | Mit der Unreal Engine erstellte Wasserszene",
    },
    {
      type: "image",
      url: "https://lichtgst.uber.space/photos/projects/innellea-live-2024/innellea-live-2024-lichtgestalten-1.jpg",
      altEn: "Innellea The Belonging Tour 2024 | Desert with hands created with Unreal Engine",
      altDe: "Innellea The Belonging Tour 2024 | Mit der Unreal Engine erstellte Hände in einer Wüste",
    },
    {
      type: "image",
      url: "https://lichtgst.uber.space/photos/projects/innellea-live-2024/innellea-live-2024-lichtgestalten-9.jpg",
      altEn: "Innellea The Belonging Tour 2024 | Rigged hands created with Unreal Engine and TouchDesigner",
      altDe: "Innellea The Belonging Tour 2024 | Mit der Unreal Engine und TouchDesigner erstellte Hände",
    },
    {
      type: "image",
      url: "https://lichtgst.uber.space/photos/projects/innellea-live-2024/innellea-live-2024-lichtgestalten-7.jpg",
      altEn: "Innellea The Belonging Tour 2024 | Abstract world created with Unreal Engine",
      altDe: "Innellea The Belonging Tour 2024 | Mit der Unreal Engine erstellte abstrakte Landschaft",
    },
    {
      type: "image",
      url: "https://lichtgst.uber.space/photos/projects/innellea-live-2024/innellea-live-2024-lichtgestalten-17.jpg",
      altEn: "Innellea The Belonging Tour 2024 | Abstract world created with Unreal Engine seen in Riyadh",
      altDe: "Innellea The Belonging Tour 2024 | Mit der Unreal Engine erstellte abstrakte Landschaft in Riad",
    },
    {
      type: "image",
      url: "https://lichtgst.uber.space/photos/projects/innellea-live-2024/innellea-live-2024-lichtgestalten-10.jpg",
      altEn: "Innellea The Belonging Tour 2024",
      altDe: "Innellea The Belonging Tour 2024",
    },
    {
      type: "loop",
      url: "https://lichtgst.uber.space/videoLoops/projects/innellea-live-2024/innellea-live-2024-lichtgestalten-paris-4.mp4",
      placeholder: "https://lichtgst.uber.space/photos/projects/rin-live-2023/rin-live-2023-lichtgestalten-2.jpg",
      altEn: "Innellea The Belonging Tour 2024 | Fire created with Unreal Engine at the show in Paris",
      altDe: "Innellea The Belonging Tour 2024 | Mit der Unreal Engine erstelltes Feuer in Paris",
    },
    {
      type: "image",
      url: "https://lichtgst.uber.space/photos/projects/innellea-live-2024/innellea-live-2024-lichtgestalten-18.jpg",
      altEn: "Innellea The Belonging Tour 2024 | Fly through clouds an raindrops created with TouchDesigner",
      altDe: "Innellea The Belonging Tour 2024 | Flug durch Wolken und Regentropfen, erstllt mit TouchDesigner",
    },
    {
      type: "image",
      url: "https://lichtgst.uber.space/photos/projects/innellea-live-2024/innellea-live-2024-lichtgestalten-16.jpg",
      altEn: "Innellea The Belonging Tour 2024 | Cloth simulation created with TouchDesigner",
      altDe: "Innellea The Belonging Tour 2024 | Mit TouchDesigner animierter Vorhang",
    },
  ],
  descriptionEn:
    "Working on INNELLEA’s visuals was challenging in many ways. First of all, his set combines a storytelling of fragile and vulnerable sentiments with intense and brute sounds. To match this, we created nearly 2 hours of non-looping content. Second, the composition of the 5 pillars designed by Studio Clemens Loeffelholz were a complex playground. We approached this by creating content that had a surface layer, like glass tiles, curtains, rain drops etc. to emphasize the geometric structure, while sometimes breaking completely with the boundaries of the pillars. Third, we had to keep the content adjustable to other screen formats. The content was created with Unreal Engine and TouchDesigner. We also took care of setting up a small carry-on case that allowed our operators to have a simple plug-and-play solution with timecode inputs, live video inputs and the media server.",
  descriptionDe:
    'An INNELLEAs Visuals zu arbeiten war in vielerlei Hinsicht herausfordernd. Da sein Set ein Storytelling aus zerbrechlichen und verletzlichen Gefühlen mit intensiven und brutalen Klängen kombiniert, animierten wir fast 2 Stunden nicht-loopenden Content. Zweitens waren die Kompositionen der von Studio Clemens Loeffelholz entworfenen Säulen ein komplexes Setup. Wir haben uns daher entschieden, diese Komposition mit Inhalten zu bespielen, die eine Oberflächenschicht wie Glasfliesen, Vorhänge, Regentropfen usw. hatten, um die geometrische Struktur zu betonen. Andererseits ignorierten wir die visuellen, geometrischen Grenzen der Säulen manchmal vollständig. Drittens mussten wir den Inhalt so erstellen, dass er auch auf anderen Bildschirmformaten funktioniert. Der Inhalt wurde mit der Unreal Engine und TouchDesigner erstellt. Wir haben zudem einen kleinen Handgepäckkoffer zusammengestellt, der unseren Operatorn eine einfache Plug-and-Play-Lösung mit Timecode-Eingängen, Live-Videoeingängen und dem Medienserver ermöglichte.',
  info: [
    {
      slugCreditsEn: "Date",
      slugCreditsDe: "Datum",
      values: ["03.02.2024 - ongoing"],
    },
    {
      slugCreditsEn: "Client",
      slugCreditsDe: "Kunde",
      values: [
        "Innellea #https://www.innellea.com/",
      ],
    },
    {
      slugCreditsEn: "Lead & Stage Design",
      slugCreditsDe: "Lead & Stage Design",
      values: [
        "Studio Clemens Loeffelholz #https://clemensloeffelholz.com/",
      ],
    },
    {
      slugCreditsEn: "Motion Design",
      slugCreditsDe: "Motion Design",
      values: [
        "lichtgestalten",
      ],
    },
    {
      slugCreditsEn: "Tour Lighting Director",
      slugCreditsDe: "Tour Lighting Director",
      values: [
        "Emanuel Jörgner / Steelblue Studios #https://steelbluestudios.com/",
      ],
    },
    {
      slugCreditsEn: "Audio & Media Server Setup",
      slugCreditsDe: "Audio & Media Server Setup",
      values: [
        "lichtgestalten",
      ],
    },
    {
      slugCreditsEn: "Photos / Video",
      slugCreditsDe: "Fotos / Video",
      values: [
        "Paul Gärtner #https://www.paulgärtner.com/",
        "Innellea #https://www.innellea.com/"
      ],
    },
  ],
  tags: ["artDirection", "animation"],
},
//project start
{
  shortTitle: "test",
  title: "RIN | Live 2023",
  slug: "rin-live-2023",
  imageURL:
    "https://lichtgst.uber.space/photos/projects/rin-live-2023/rin-live-2023-lichtgestalten-2.jpg",
  imageTitle: "RIN Live 2023 tour visuals, realtime VFX and operating made with TouchDesigner",
  galleryContent:[
    {
      type: "image",
      url: "https://lichtgst.uber.space/photos/projects/rin-live-2023/rin-live-2023-lichtgestalten-2.jpg",
      altEn: "RIN Live 2023 tour visuals, realtime VFX and operating made with TouchDesigner",
      altDe: "RIN Live 2023 Tour Visuals, Echtzeit-VFX und Operating mit TouchDesigner",
    },
    {
      type: "image",
      url: "https://lichtgst.uber.space/photos/projects/rin-live-2023/rin-live-2023-lichtgestalten-7.jpg",
      altEn: "RIN Live 2023 tour visuals, realtime VFX and operating made with TouchDesigner",
      altDe: "RIN Live 2023 Tour Visuals, Echtzeit-VFX und Operating mit TouchDesigner",
    },
    {
      type: "loop",
      url: "https://lichtgst.uber.space/videoLoops/projects/rin-live-2023/rin-live-2023-lichtgestalten-video2.mp4",
      placeholder: "https://lichtgst.uber.space/photos/projects/rin-live-2023/rin-live-2023-lichtgestalten-2.jpg",
      altEn: "RIN Live 2023 tour, view on the crowd in Frankfurt",
      altDe: "RIN Live 2023 Tour, Sicht aufs Publikum in Frankfurt",
    },
    {
      type: "image",
      url: "https://lichtgst.uber.space/photos/projects/rin-live-2023/rin-live-2023-lichtgestalten-16.jpg",
      altEn: "RIN Live 2023 tour visuals, Schmyt feature, realtime VFX and operating made with TouchDesigner",
      altDe: "RIN Live 2023 Tour Visuals, Schmyt, Echtzeit-VFX und Operating mit TouchDesigner",
    },
    {
      type: "image",
      url: "https://lichtgst.uber.space/photos/projects/rin-live-2023/rin-live-2023-lichtgestalten-12.jpg",
      altEn: "RIN Live 2023 tour visuals, operating made with TouchDesigner, Stuttgart",
      altDe: "RIN Live 2023 Tour Visuals, Operating mit TouchDesigner in Stuttgart",
    },
    {
      type: "image",
      url: "https://lichtgst.uber.space/photos/projects/rin-live-2023/rin-live-2023-lichtgestalten-9.jpg",
      altEn: "RIN Live 2023 tour visuals, Need for Speed, operating with TouchDesigner",
      altDe: "RIN Live 2023 Tour Visuals, Echtzeit-VFX und Operating mit TouchDesigner",
    },
    {
      type: "loop",
      url: "https://lichtgst.uber.space/videoLoops/projects/rin-live-2023/rin-live-2023-lichtgestalten-video1.mp4",
      placeholder: "https://lichtgst.uber.space/photos/projects/rin-live-2023/rin-live-2023-lichtgestalten-2.jpg",
      altEn: "RIN Live 2023 tour visuals, operating with TouchDesigner, sold out Lanxess-Arena in Cologne",
      altDe: "RIN Live 2023 Tour Visuals, Operating mit TouchDesigner in der ausverkauften Lanxess-Arne in Köln",
    },
    {
      type: "image",
      url: "https://lichtgst.uber.space/photos/projects/rin-live-2023/rin-live-2023-lichtgestalten-8.jpg",
      altEn: "RIN Live 2023 tour visuals, realtime masking and operating made with TouchDesigner",
      altDe: "RIN Live 2023 Tour Visuals, Echtzeit-VFX und Operating mit TouchDesigner",
    },
    {
      type: "image",
      url: "https://lichtgst.uber.space/photos/projects/rin-live-2023/rin-live-2023-lichtgestalten-4.jpg",
      altEn: "RIN Live 2023 tour visuals, realtime VFX and operating made with TouchDesigner",
      altDe: "RIN Live 2023 Tour Visuals, Echtzeit-VFX und Operating mit TouchDesigner",
    },
    {
      type: "image",
      url: "https://lichtgst.uber.space/photos/projects/rin-live-2023/rin-live-2023-lichtgestalten-23.jpg",
      altEn: "RIN Live 2023 tour, B-stage in Berlin",
      altDe: "RIN Live 2023 Tour Visuals, B-Stage in Berlin",
    },
    {
      type: "image",
      url: "https://lichtgst.uber.space/photos/projects/rin-live-2023/rin-live-2023-lichtgestalten-24.jpg",
      altEn: "RIN Live 2023 tour visuals, realtime VFX and operating made with TouchDesigner",
      altDe: "RIN Live 2023 Tour Visuals, Echtzeit-VFX und Operating mit TouchDesigner",
    },
  ],
  descriptionEn:
    "The stage design for the RIN Live tour 2023 introduced a new approach to the storytelling in his live shows. Though each chapter had its own unique setting, we wanted to keep RIN at the core of the visual narrative. We continued to use our realtime machine-learning algorithm to key out RIN from our two live camera inputs. Combining this live feed with realtime VFX and pre-rendered content allowed us to work with complex compositing setups. The programming, operating and realtime shading of the video content was made with TouchDesigner.",
  descriptionDe:
    'Das Bühnendesign für die RIN Live Tour 2023 führte eine neue Erzählweise mit unterschiedlichen visuellen und inhaltlichen Kapiteln ein. Als grundlegendes Gestaltungsmerkmal wurde wieder unser Echtzeit-Algorithmus verwendet, um die Umrisse von RIN aus den Live-Kameras zu maskieren. Die Kombination dieses Live-Feeds mit Echtzeit-VFX und vorgerenderten Inhalten ermöglichte es uns, mit komplexen Kompositionen zu arbeiten. Die Programmierung, das Operating und alle Echtzeit-Effekte wurden mit TouchDesigner umgesetzt.',
  info: [
    {
      slugCreditsEn: "Date",
      slugCreditsDe: "Datum",
      values: ["23.11. - 22.12.2023"],
    },
    {
      slugCreditsEn: "Client",
      slugCreditsDe: "Kunde",
      values: [
        "RIN / Landstreicher Booking #https://landstreicher-booking.de/artists/rin",
      ],
    },
    {
      slugCreditsEn: "Lead & Stage Design",
      slugCreditsDe: "Lead & Stage Design",
      values: [
        "Studio Clemens Loeffelholz #https://clemensloeffelholz.com/",
      ],
    },
    {
      slugCreditsEn: "Concept",
      slugCreditsDe: "Konzept",
      values: [
        "Studio Clemens Loeffelholz #https://clemensloeffelholz.com/", 
        "Bewegtbildgrafik #https://bewegtbildgrafik.de/", 
        "lichtgestalten",
      ],
    },
    {
      slugCreditsEn: "Pre-Rendered Motion Design",
      slugCreditsDe: "Pre-Rendered Motion Design",
      values: [
        "Bewegtbildgrafik #https://bewegtbildgrafik.de/",
      ],
    },
    {
      slugCreditsEn: "Realtime Motion Design",
      slugCreditsDe: "Echtzeit Motion Design",
      values: [
        "lichtgestalten",
      ],
    },
    {
      slugCreditsEn: "Tour Lighting Director",
      slugCreditsDe: "Tour Lighting Director",
      values: [
        "Emanuel Jörgner / Steelblue Studios #https://steelbluestudios.com/",
      ],
    },
    {
      slugCreditsEn: "Operating",
      slugCreditsDe: "Operating",
      values: [
        "lichtgestalten",
      ],
    },
    {
      slugCreditsEn: "Photos / Video",
      slugCreditsDe: "Fotos / Video",
      values: [
        "Paul Gärtner #https://www.paulgärtner.com/",
        "Emanuel Jörgner #https://steelbluestudios.com/",
        "Philipp Brezhniev #https://www.popsiclemane.com/",
      ],
    },
    {
      slugCreditsEn: "Locations",
      slugCreditsDe: "Locations",
      values: [
        "Sporthalle Hamburg", 
        "Max-Schmeling-Halle Berlin", 
        "Zenith München", 
        "Gasometer Wien",
        "Hallenstadion Zürich",  
        "Festhalle Frankfurt",
        "Lanxess Arena Köln",
        "Schleyer-Halle Stuttgart",   
      ],
    },
  ],
  tags: ["artDirection", "animation", "realtime"],
},
//project start
  {
    shortTitle: "test",
    title: "Reeperbahn Festival 2022 | Hamburg",
    slug: "reeperbahn-festival-2022",
    imageURL:
      "https://lichtgst.uber.space/photos/projects/reeperbahn-festival-2022/lichtgestalten-videoinstallation-reeberpahn-festival-2022-20.jpg",
    imageTitle: "Video Installation IN BETWEEN Reeperbahn Festival 2022",
    galleryContent:[
      {
        type: "image",
        url: "https://lichtgst.uber.space/photos/projects/reeperbahn-festival-2022/lichtgestalten-videoinstallation-reeberpahn-festival-2022-20.jpg",
        altEn: "Video installation with 24 screens in a container at IN BETWEEN at Reeperbahn Festival 2022",
        altDe: "Videoinstallation mit 24 Bildschirmen in einem Container bei IN BETWEEN Reeperbahn Festival 2022",
      },
      {
        type: "image",
        url: "https://lichtgst.uber.space/photos/projects/reeperbahn-festival-2022/lichtgestalten-videoinstallation-reeberpahn-festival-2022-12.jpg",
        altEn: "Video art installation in Hamburg, generative programming with TouchDesigner",
        altDe: "Videokunstinstallation in Hamburg, generative Programmierung mit TouchDesigner",
      },
      {
        type: "loop",
        url: "https://lichtgst.uber.space/videoLoops/projects/reeperbahn-festival-2022/lichtgestalten-videoinstallation-in-betweeen-reeperbahnfestival-2022-1.mp4",
        placeholder: "https://lichtgst.uber.space/photos/projects/fridas-pier-stuttgart/lichtgestalten-fridas-pier-2.jpg",
        altEn: "Video installation with 24 screens in a container at IN BETWEEN at Reeperbahn Festival 2022, Hamburg",
        altDe: "Videoinstallation mit 24 Bildschirmen in einem Container bei IN BETWEEN Reeperbahn Festival 2022, Hamburg",
      },
      {
        type: "image",
        url: "https://lichtgst.uber.space/photos/projects/reeperbahn-festival-2022/lichtgestalten-videoinstallation-reeberpahn-festival-2022-13.jpg",
        altEn: "Video installation with 24 screens in a container at IN BETWEEN at Reeperbahn Festival 2022, Hamburg",
        altDe: "Videoinstallation mit 24 Bildschirmen in einem Container bei IN BETWEEN Reeperbahn Festival 2022, Hamburg",
      },
      {
        type: "image",
        url: "https://lichtgst.uber.space/photos/projects/reeperbahn-festival-2022/lichtgestalten-videoinstallation-reeberpahn-festival-2022-14.jpg",
        altEn: "Video installation with 24 screens in a container at IN BETWEEN at Reeperbahn Festival 2022, Hamburg",
        altDe: "Videoinstallation mit 24 Bildschirmen in einem Container bei IN BETWEEN Reeperbahn Festival 2022, Hamburg",
      },
      {
        type: "loop",
        url: "https://lichtgst.uber.space/videoLoops/projects/reeperbahn-festival-2022/lichtgestalten-videoinstallation-in-betweeen-reeperbahnfestival-2022-3.mp4",
        placeholder: "https://lichtgst.uber.space/photos/projects/reeperbahn-festival-2022/lichtgestalten-fridas-pier-2.jpg",
        altEn: "Subdivison shader running in realtime for Reeperbahn Festival 2022, ",
        altDe: "Echtzeit Subdivison Shader beim Reeperbahn Festival 2022",
      },
      {
        type: "image",
        url: "https://lichtgst.uber.space/photos/projects/reeperbahn-festival-2022/lichtgestalten-videoinstallation-reeberpahn-festival-2022-19.jpg",
        altEn: "Podium discussion with the Heart Directors Club, Golnar Kat Rahmani und Simon Wahlers",
        altDe: "Podiumsdiskussion mit dem Heart Directors Club, Golnar Kat Rahmani und Simon Wahlers",
      },
      {
        type: "image",
        url: "https://lichtgst.uber.space/photos/projects/reeperbahn-festival-2022/lichtgestalten-videoinstallation-reeberpahn-festival-2022-18.jpg",
        altEn: "Podium discussion about political design with the Heart Directors Club, Golnar Kat Rahmani und Simon Wahlers",
        altDe: "Podiumsdiskussion über politisches Design mit dem Heart Directors Club, Golnar Kat Rahmani und Simon Wahlers",
      },
      {
        type: "image",
        url: "https://lichtgst.uber.space/photos/projects/reeperbahn-festival-2022/lichtgestalten-videoinstallation-reeberpahn-festival-2022-23.jpg",
        altEn: "Video installation with 24 screens in a container at IN BETWEEN at Reeperbahn Festival 2022, Hamburg",
        altDe: "Videoinstallation mit 24 Bildschirmen in einem Container bei IN BETWEEN Reeperbahn Festival 2022, Hamburg",
      },
      {
        type: "loop",
        url: "https://lichtgst.uber.space/videoLoops/projects/reeperbahn-festival-2022/lichtgestalten-videoinstallation-in-betweeen-reeperbahnfestival-2022-2.mp4",
        placeholder: "https://lichtgst.uber.space/photos/projects/reeperbahn-festival-2022/lichtgestalten-fridas-pier-2.jpg",
        altEn: "High resolution video installation with 24 screens in Hamburg, Reeperbahn Festival 2022",
        altDe: "Hochauflösende Videoinstallation mit 24 Bildschirmen in Hamburg, Reeperbahn Festival 2022",
      },
      {
        type: "image",
        url: "https://lichtgst.uber.space/photos/projects/reeperbahn-festival-2022/lichtgestalten-videoinstallation-reeberpahn-festival-2022-22.jpg",
        altEn: "Realtime video installation with 24 screens in a container at IN BETWEEN at Reeperbahn Festival 2022, Hamburg",
        altDe: "Videoinstallation mit 24 Bildschirmen in einem Container bei IN BETWEEN Reeperbahn Festival 2022, Hamburg",
      },
      {
        type: "image",
        url: "https://lichtgst.uber.space/photos/projects/reeperbahn-festival-2022/lichtgestalten-videoinstallation-reeberpahn-festival-2022-24.jpg",
        altEn: "Video installation with 24 screens in a container at IN BETWEEN at Reeperbahn Festival 2022, Hamburg",
        altDe: "Videoinstallation mit 24 Bildschirmen in einem Container bei IN BETWEEN Reeperbahn Festival 2022, Hamburg",
      },
    ],
    descriptionEn:
      "For the third installment of '(IN) BETWEEN', an exhibiton that highlights visual design within the field of music, Bareis + Nicolaus invited designers and artists from all over the world to submit their digital music covers. These covers were not limited to any genres but were supposed to be designed and released between 01.01.2020 – 31.08.2022. To exhibit this wide array of artworks, we decided to work with 24 digital screens to let the spectators immerse in a room filled with randomly sized versions of the artwork. By programming the animations in a generative manner, the subdivisions, sizing, transitions and selection of the artwoks followed a set of rules that allowed a random order. No arrangement looked the same, filling roughly 22.000 x 6.000 pixels with over 400 artworks in realtime. A live input mirrored on all screens was used for the panel discussion by the »Heart Directors Club« with Golnar Kat Rahmani and Simon Wahlers on political / critical design.",
    descriptionDe:
      '"(IN) BETWEEEN" ist eine Ausstellungsreihe, die sich mit visuellem Design rund um das Thema Musik auseinandersetzt. Mit dem Reeperbahn Festival 2022 Jahr fand sie nun zum dritten Mal statt. Ein von Bareis + Nicolaus initiierter Open Call lud Desginer*innen und Künstler*innen aus der ganzen Welt dazu ein, ihre zwischen 01.01.2020 - 31.08.2022 entworfenen und veröffentlichten digitalen Musikcover einzureichen. Um diesem breiten Spektrum an Kunstwerken eine entsprechende Darstellungsform zu geben, entschieden wir uns, 24 digitale Bildschirme in 3 Seecontainern zu installieren. Darauf wurden die Cover in zufälliger Anordnung und Größen gezeigt, damit die Zuschauer in das Werk eintauchen und bei jedem Blick etwas neues entdecken konnten. Durch eine generative Programmierung konnten die Animationen, die Anordnung und Reihenfolge innerhalb eines Regelwerks zufällig und jedesmal einmalig sein. Insgesamt wurden so in Echtzeit über 400 Werke in einer Gesamtauflösung von ca. 22.000 x 6.000 Pixeln dargestellt. Das Setup konnte durch einen Live-Input auch als Bühne für die vom >>Heart Directors Club<< geführte Paneldiskussion mit Golnar Kat Rahmani und Simon Wahlers zu politischem und kritischem Design genutzt werden.',
    info: [
      {
        slugCreditsEn: "Date",
        slugCreditsDe: "Datum",
        values: ["21. - 24.09.2022"],
      },
      {
        slugCreditsEn: "Client",
        slugCreditsDe: "Kunde",
        values: [
          "Reeperbahn Festival #https://www.reeperbahnfestival.com/",
        ],
      },
      {
        slugCreditsEn: "Curation and Overall Concept",
        slugCreditsDe: "Kuration und Gesamtkonzept",
        values: [
          "Bareis + Nicolaus #https://www.bareis-nicolaus.com/",
        ],
      },
      {
        slugCreditsEn: "Video Installation | Concept, Planning and Realisation",
        slugCreditsDe: "Videoinstallation | Konzept, Planung und Umsetzung",
        values: [
          "lichtgestalten",
        ],
      },
      {
        slugCreditsEn: "Artworks",
        slugCreditsDe: "Artworks",
        values: [
          "(01 & 03) Permanent Vacation #https://perm-vac.com/",
          "(02 & 09) Dominik Keller #http://www.dominikkeller.de/",
          "(04 & 06) Kseniia Stavrova / Orka Collective #http://orkacollective.com/",
          "(05) Stefanie Röhnisch #https://stefanieroehnisch.de/",
          "(06) Bureau Mia #https://bureaumia.ch/",
          "(07 & 08) Simon Wahlers #https://www.zweifel.jetzt/",
          "(10) Miró Ingmar Tiebe #https://www.instagram.com/m.i.r.u.e.l/?hl=de",
          "(10) Bareis + Nicolaus #https://www.bareis-nicolaus.com/",
          "(11) Johannes Walenta #https://www.behance.net/johanneswalenta",
          "(12) Martha Wurmus #https://marthawurmus.com/",
        ],
      },
    ],
    tags: ["artDirection", "animation", "installation", "realtime"],
  },
  //project start
  {
    shortTitle: "test",
    title: "Festival Der Zukunft 2022 | Munich",
    slug: "festival-der-zukunft-2022",
    imageURL:
      "https://lichtgst.uber.space/photos/projects/festival-der-zukunft-2022/lichtgestalten-dome-mapping-festival-der-zukunft-2023-2.jpg",
    imageTitle: "Video Installation IN BETWEEN Reeperbahn Festival 2022",
    galleryContent:[
      {
        type: "image",
        url: "https://lichtgst.uber.space/photos/projects/festival-der-zukunft-2022/lichtgestalten-dome-mapping-festival-der-zukunft-2023-2.jpg",
        altEn: "Full dome mapping at the Deutsches Museum in Munich for the 'Festival of the Future 2022'",
        altDe: "Kuppelprojektion im Deutschen Museum München für das 'Festival der Zukunft 2022'",
      },
      {
        type: "loop",
        url: "https://lichtgst.uber.space/videoLoops/projects/festival-der-zukunft-2022/lichtgestalten-festival-der-zukunft-2022-full-dome-mapping.mp4",
        placeholder: "https://lichtgst.uber.space/photos/projects/fridas-pier-stuttgart/lichtgestalten-fridas-pier-2.jpg",
        altEn: "Full dome mapping at the Deutsches Museum in Munich for the 'Festival of the Future 2022'",
        altDe: "Kuppelprojektion im Deutschen Museum München für das 'Festival der Zukunft 2022'",
      },
      {
        type: "image",
        url: "https://lichtgst.uber.space/photos/projects/festival-der-zukunft-2022/lichtgestalten-dome-mapping-festival-der-zukunft-2023-13.jpg",
        altEn: "Full dome mapping at the Deutsches Museum in Munich for the 'Festival of the Future 2022'",
        altDe: "Kuppelprojektion im Deutschen Museum München für das 'Festival der Zukunft 2022'",
      },
      {
        type: "image",
        url: "https://lichtgst.uber.space/photos/projects/festival-der-zukunft-2022/lichtgestalten-dome-mapping-festival-der-zukunft-2023-4.jpg",
        altEn: "Full dome mapping at the Deutsches Museum in Munich for the 'Festival of the Future 2022'",
        altDe: "Kuppelprojektion im Deutschen Museum München für das 'Festival der Zukunft 2022'",
      },
      {
        type: "image",
        url: "https://lichtgst.uber.space/photos/projects/festival-der-zukunft-2022/lichtgestalten-dome-mapping-festival-der-zukunft-2023-6.jpg",
        altEn: "Full dome mapping at the Deutsches Museum in Munich for the 'Festival of the Future 2022'",
        altDe: "Kuppelprojektion im Deutschen Museum München für das 'Festival der Zukunft 2022'",
      },
      {
        type: "image",
        url: "https://lichtgst.uber.space/photos/projects/festival-der-zukunft-2022/lichtgestalten-dome-mapping-festival-der-zukunft-2023-11.jpg",
        altEn: "Full dome mapping at the Deutsches Museum in Munich for the 'Festival of the Future 2022'",
        altDe: "Kuppelprojektion im Deutschen Museum München für das 'Festival der Zukunft 2022'",
      },
      {
        type: "loop",
        url: "https://lichtgst.uber.space/videoLoops/projects/festival-der-zukunft-2022/lichtgestalten-festival-der-zukunft-2022-kingdom-of-not.mp4",
        altEn: "Full dome mapping at the Deutsches Museum in Munich for the 'Festival of the Future 2022'",
        altDe: "Kuppelprojektion im Deutschen Museum München für das 'Festival der Zukunft 2022'",
      },
      {
        type: "image",
        url: "https://lichtgst.uber.space/photos/projects/festival-der-zukunft-2022/lichtgestalten-dome-mapping-festival-der-zukunft-2023-3.jpg",
        altEn: "Full dome mapping at the Deutsches Museum in Munich for the 'Festival of the Future 2022'",
        altDe: "Kuppelprojektion im Deutschen Museum München für das 'Festival der Zukunft 2022'",
      },
      {
        type: "image",
        url: "https://lichtgst.uber.space/photos/projects/festival-der-zukunft-2022/lichtgestalten-dome-mapping-festival-der-zukunft-2023-10.jpg",
        altEn: "Full dome mapping at the Deutsches Museum in Munich for the 'Festival of the Future 2022'",
        altDe: "Kuppelprojektion im Deutschen Museum München für das 'Festival der Zukunft 2022'",
      },
    ],
    descriptionEn:
      "As the 1E9 Conference grew into the 'Festival Of The Future', the 180° dome projection we made in 2021 doubled its size to cover the full dome inside the former planetarium at the Deutsches Museum in Munich. To set the mood for 3 days full of keynotes and panels about our environment, social and economic challenges, technologies and art, we created a broad variety of atmospheric animations depicting the topics in abstract ways. In addition to that we arranged around 100 photos showing the history of the building in a seemless looping 'time tunnel' for the opening ceremony of the 'Forum der Zukunft', which transformed into a warp speed tunnel by the press of a button. Together with TILDE, a sound art & new media studio based in Istanbul, we performed an audivisual show where Mehmet Ünal played VR instruments. Last but not least, we contributed with our 360° artwork 'Kingdom Of Not' to the 'Festival Of The Future'.",
    descriptionDe:
      'Nachdem die 1E9 Konferenz innerhalb von 2 Jahren zum "Festival Der Zukunft" gewachsen ist, konnten wir das begleitende Mapping nun auch auf eine 360°-Fulldomeprojektion im ehemaligen Planetarium des Deutschen Museums vergrößern. Drei Tage gefüllt mit Präsentationen und Diskussionen rund um die Themen Umwelt, soziale und ökonomische Herausforderungen, Technolgien und Kunst untermalten wir mit abstrakten, auf die Themen abgestimmten Projektionen. Zudem arrangierten wir zur Eröffnungszeremonie des "Forums der Zukunft" ca. 100 historische Fotos des Deutschen Museums zu einem sich unendlich wiederholenden Zeittunnel. Zusammen mit TILDE, einem Sound Art & New Media Studio aus Istanbul, setzten wir eine audiovisuelle Performance um, bei der Mehmet Ünal auf VR Instrumenten spielte. Unser 360°-Videokunstwerk "Kingdom Of Not" wurde beim "Festival Der Zukunft" das erste Mal gezeigt.',
    info: [
      {
        slugCreditsEn: "Date",
        slugCreditsDe: "Datum",
        values: ["22. - 24.07.2022"],
      },
      {
        slugCreditsEn: "Client",
        slugCreditsDe: "Kunde",
        values: [
          "1E9 #https://1e9.community/",
          "Deutsches Museum #https://www.deutsches-museum.de/",
        ],
      },
      {
        slugCreditsEn: "Event & Lead",
        slugCreditsDe: "Event und Produktion",
        values: [
          "Safe Agency #https://safeagency.cc/",
        ],
      },
      {
        slugCreditsEn: "Full Dome Mapping | Concept, Planning, Operating and Content",
        slugCreditsDe: "Kuppelprojektion | Konzept, Planung, Durchführung und Inhalteerstellung",
        values: [
          "lichtgestalten",
        ],
      },
      {
        slugCreditsEn: "Documentation",
        slugCreditsDe: "Dokumentation",
        values: [
          "dropin.tv #https://dropintv.de/",
          "Hans-Martin Kudlinski #https://kudlinski-photo.com/",
          "lichtgestalten",
        ],
      },
    ],
    tags: ["artDirection", "animation", "mapping", "installation"],
  },
  //project start
  {
    shortTitle: "test",
    title: "9ms x lichtgestalten | Munich",
    slug: "9ms-planetarium-deutsches-museum-2022",
    imageURL:
      "https://lichtgst.uber.space/photos/projects/9ms-planetarium-deutsches-museum-2022/lichtgestalten-9ms-planetarium-deutsches-museum-2022-8.jpg",
    imageTitle: "Live audiovisual concert 9ms with realtime dome mapping by lichtgestalten",
    galleryContent:[
      {
        type: "image",
        url: "https://lichtgst.uber.space/photos/projects/9ms-planetarium-deutsches-museum-2022/lichtgestalten-9ms-planetarium-deutsches-museum-2022-8.jpg",
        altEn: "Live audiovisual concert with 9ms at the Deutsches Museum in Munich during the 'Festival of the Future 2022'",
        altDe: "Audiovisuelles Live-Konzert mit 9ms im Deutschen Museum München beim 'Festival der Zukunft 2022'",
      },
      {
        type: "image",
        url: "https://lichtgst.uber.space/photos/projects/9ms-planetarium-deutsches-museum-2022/lichtgestalten-9ms-planetarium-deutsches-museum-2022-6.jpg",
        altEn: "Florian König and Simon Popp playing live drums in a full dome projection at Deutsches Museum Munich",
        altDe: "Florian König und Simon Popp bei einem Konzert mit einer Kuppelprojektion im Deutschen Museum München",
      },
      {
        type: "image",
        url: "https://lichtgst.uber.space/photos/projects/9ms-planetarium-deutsches-museum-2022/lichtgestalten-9ms-planetarium-deutsches-museum-2022-5.jpg",
        altEn: "Full dome mapping at the Deutsches Museum in Munich with 9ms playing live",
        altDe: "Kuppelprojektion im Deutschen Museum München während 9ms ein Live-Konzert spielen",
      },
      {
        type: "image",
        url: "https://lichtgst.uber.space/photos/projects/9ms-planetarium-deutsches-museum-2022/lichtgestalten-9ms-planetarium-deutsches-museum-2022-14.jpg",
        altEn: "New media art dome projection at Deutsches Museum Munich with music by 9ms",
        altDe: "Medienkunst und Kuppelprojektion mit 9ms und lichtgestalten im Deutschen Museum München",
      },
      {
        type: "image",
        url: "https://lichtgst.uber.space/photos/projects/9ms-planetarium-deutsches-museum-2022/lichtgestalten-9ms-planetarium-deutsches-museum-2022-2.jpg",
        altEn: "Full dome mapping at the Deutsches Museum in Munich for the 'Festival of the Future 2022'",
        altDe: "Kuppelprojektion im Deutschen Museum München für das 'Festival der Zukunft 2022'",
      },
      {
        type: "image",
        url: "https://lichtgst.uber.space/photos/projects/9ms-planetarium-deutsches-museum-2022/lichtgestalten-9ms-planetarium-deutsches-museum-2022-15.jpg",
        altEn: "Florian König and Simon Popp playing live drums in a full dome projection at Deutsches Museum Munich",
        altDe: "Florian König und Simon Popp bei einem Konzert mit einer Kuppelprojektion im Deutschen Museum München",
      },
      {
        type: "image",
        url: "https://lichtgst.uber.space/photos/projects/9ms-planetarium-deutsches-museum-2022/lichtgestalten-9ms-planetarium-deutsches-museum-2022-7.jpg",
        altEn: "Live audiovisual concert with 9ms at the Deutsches Museum in Munich during the 'Festival of the Future 2022'",
        altDe: "Audiovisuelles Live-Konzert mit 9ms im Deutschen Museum München beim 'Festival der Zukunft 2022'",
      },
    ],
    descriptionEn:
      "We had the unique opportunity to invite 9ms for an audiovisual live concert inside of the abandoned planetarium at the Deutsches Museum Munich. Watching and listening to Simon Popp and Florian König is an experience itself, so we decided to keep the eye-filling projection slow paced and atmospheric. As the animations were generated in realtime, certain triggers like kick drums or inputs from 9ms' modular system made the projections react to the musicians' movements and their ideas. Thanks to Deutsches Museum, 1E9 Community and Safe Agency for making this special concert possible.",
    descriptionDe:
      'Wir hatten die einzigartige Gelegenheit, das Schlagzeugduo 9ms in das ehemalige Planetarium des Deutschen Museums in München einzuladen. Da das Zusammenspiel zwischen Simon Popp und Florian König schon ein sehr spannendes Erlebnis ist, haben wir die blickfüllende Projektion bewusst subtil, langsam und atmosphärisch konzipiert. Da die Inhalte vollständig in Echtzeit generiert wurden, konnten die beiden Musiker durch Signale von Synthesizern, Kick Drums oder Antennen die Projektionen beeinflussen. Vielen Dank an das Deutsche Museum, die 1E9 Community sowie die Safe Agency, die dieses Konzert möglich gemacht haben.',
    info: [
      {
        slugCreditsEn: "Date",
        slugCreditsDe: "Datum",
        values: ["23.07.2022"],
      },
      {
        slugCreditsEn: "Client",
        slugCreditsDe: "Kunde",
        values: [
          "1E9 #https://1e9.community/",
          "Deutsches Museum #https://www.deutsches-museum.de/",
        ],
      },
      {
        slugCreditsEn: "Event & Lead",
        slugCreditsDe: "Event und Produktion",
        values: [
          "Safe Agency #https://safeagency.cc/",
        ],
      },
      {
        slugCreditsEn: "Full Dome Mapping | Concept, Technical Planning, Operating and Content",
        slugCreditsDe: "Kuppelprojektion | Konzept, Technische Planung, Durchführung und Inhalteerstellung",
        values: [
          "lichtgestalten",
        ],
      },
      {
        slugCreditsEn: "Photos",
        slugCreditsDe: "Fotos",
        values: [
          "Sebastian Huber #https://www.sebastianstefanhuber.com/",
        ],
      },
    ],
    tags: ["artDirection", "animation", "mapping", "installation", "realtime"],
  },
  //project start
  {
    shortTitle: "test",
    title: "Fridas Pier | Stuttgart",
    slug: "fridas-pier-stuttgart",
    imageURL:
      "https://lichtgst.uber.space/photos/projects/fridas-pier-stuttgart/lichtgestalten-fridas-pier-2.jpg",
    imageTitle: "Light Installation Fridas Pier | Stuttgart",
    galleryContent:[
      {
        type: "image",
        url: "https://lichtgst.uber.space/photos/projects/fridas-pier-stuttgart/lichtgestalten-fridas-pier-2.jpg",
        altEn: "Light installation at Fridas Pier, Stuttgart",
        altDe: "Lichtinstallation, Fridas Pier, Stuttgart",
      },
      {
        type: "loop",
        url: "https://lichtgst.uber.space/videoLoops/projects/fridas-pier-stuttgart/lichtgestalten-shdw-obscure-shape-at-fridas-pier.mp4",
        placeholder: "https://lichtgst.uber.space/photos/projects/fridas-pier-stuttgart/lichtgestalten-fridas-pier-2.jpg",
        altEn: "Light show at the SHDW & Obscure Shape set at Fridas Pier, Stuttgart",
        altDe: "Lichtshow beim Auftritt von SHDW & Obscure Shape in Fridas Pier, Stuttgart",
      },
      {
        type: "image",
        url: "https://lichtgst.uber.space/photos/projects/fridas-pier-stuttgart/lichtgestalten-fridas-pier-11.jpg",
        altEn: "Light installation at Fridas Pier, Stuttgart",
        altDe: "Lichtinstallation, Fridas Pier, Stuttgart",
      },
      {
        type: "image",
        url: "https://lichtgst.uber.space/photos/projects/fridas-pier-stuttgart/lichtgestalten-fridas-pier-12.jpg",
        altEn: "Light installation at Fridas Pier, Stuttgart",
        altDe: "Lichtinstallation, Fridas Pier, Stuttgart",
      },
      {
        type: "image",
        url: "https://lichtgst.uber.space/photos/projects/fridas-pier-stuttgart/lichtgestalten-fridas-pier-13.jpg",
        altEn: "DJ setup at Fridas Pier, Stuttgart",
        altDe: "DJ-Pult in Fridas Pier, Stuttgart",
      },
      {
        type: "loop",
        url: "https://lichtgst.uber.space/videoLoops/projects/fridas-pier-stuttgart/lichtgestalten-fridas-pier-light-installation.mp4",
        altEn: "Light installation at Fridas Pier, Stuttgart",
        altDe: "Lichtinstallation, Fridas Pier, Stuttgart",
      },
      {
        type: "image",
        url: "https://lichtgst.uber.space/photos/projects/fridas-pier-stuttgart/lichtgestalten-fridas-pier-17.jpg",
        altEn: "Light installation at Fridas Pier, Stuttgart",
        altDe: "Lichtinstallation, Fridas Pier, Stuttgart",
      },
      {
        type: "image",
        url: "https://lichtgst.uber.space/photos/projects/fridas-pier-stuttgart/lichtgestalten-fridas-pier-16.jpg",
        altEn: "Light installation at Fridas Pier, Stuttgart",
        altDe: "Lichtinstallation, Fridas Pier, Stuttgart",
      },
      {
        type: "image",
        url: "https://lichtgst.uber.space/photos/projects/fridas-pier-stuttgart/lichtgestalten-fridas-pier-1.jpg",
        altEn: "Light installation at Fridas Pier, Stuttgart",
        altDe: "Lichtinstallation, Fridas Pier, Stuttgart",
      },
    ],
    descriptionEn:
      "Fridas Pier is an event location in an old coal cargo ship. We were asked to design a light installation for its main floor which could be used for events and clubbing. Together with Simon Wallenda we derived a design concept paying tribute to the locations history and that allows a playful interaction of audiovisual effects and the location. 64 custom made 3D printed fixtures, 4 moving heads, 8 spot lights, fog and haze can be controlled by a simple web interface using a tablet or via video mapping. An autonomous mode allows detection of the songs bpm, breaks or drops in 5 different intensity styles.",
    descriptionDe:
      "Fridas Pier ist eine Eventlocation rund um den alten Kohlefrachter Wilhelm Knipscheer an der alten Kohleverladestelle in Stuttgart. Wir wurden damit beauftragt, eine dauerhafte Lichtinstallation für den Club- und Veranstaltungsbetrieb auf dem Main Floor zu gestalten. Zusammen mit Simon Wallenda entstand ein Design, dass sich an der Geschichte der Räumlichkeiten orientiert und ein großes Spektrum für ein audiovisuelles Zusammenspiel ermöglicht. 64 3D-gedruckte Lichtobjekte, 4 Moving Heads, 8 Scheinwerfer sowie die Nebelmaschine können über ein intuitives Webinterface per Tablet bedient oder via Video Mapping angesteuert werden. Ein Automatikmodus erkennt die Geschwindigkeit und unterschiedlichen Intensitätsstufen der Musik und spielt je nach ausgewählter Stimmung die passenden räumlichen Lichtanimationen ab.",
    info: [
      {
        slugCreditsEn: "Date",
        slugCreditsDe: "Datum",
        values: ["05/2022 - now"],
      },
      {
        slugCreditsEn: "Client",
        slugCreditsDe: "Kunde",
        values: [
          "Fridas Pier #https://www.fridaspier.de/",
        ],
      },
      {
        slugCreditsEn: "Concept, Design & Realization",
        slugCreditsDe: "Konzept, Design & Umsetzung",
        values: [
          "Simon Wallenda", 
          "lichtgestalten",
        ],
      },
      {
        slugCreditsEn: "UI & Audioreactive Programming",
        slugCreditsDe: "Benutzeroberfläche & audioreaktive Programmierung",
        values: [
          "lichtgestalten",
        ],
      },
      {
        slugCreditsEn: "Photos / Video",
        slugCreditsDe: "Fotos / Video",
        values: [
          "Jan Potente #https://www.janpotente.de/",
          "Anna Fruth #https://www.instagram.com/aen.so.media/",
          "Kriss Turic #https://www.instagram.com/krissturic/?hl=de",
          "SHDW & Obscure Shape #https://www.instagram.com/shdw_obscureshape/",
        ],
      },
    ],
    tags: ["artDirection", "installation"],
  },
  //project start
  {
    shortTitle: "test",
    title: "RIN | Live 2022",
    slug: "rin-live-2022",
    imageURL:
      "https://lichtgst.uber.space/photos/projects/rin-live-22/lichtgestalten-rin-live-22-9.jpg",
    imageTitle: "RIN | Live 2022",
    galleryContent:[
      {
        type: "image",
        url: "https://lichtgst.uber.space/photos/projects/rin-live-22/lichtgestalten-rin-live-22-9.jpg",
        altEn: "RIN live visuals at Splash! Festival 2022",
        altDe: "RIN live visuals beim Splash! Festival 2022",
      },
      {
        type: "image",
        url: "https://lichtgst.uber.space/photos/projects/rin-live-22/lichtgestalten-rin-live-22-10.jpg",
        altEn: "Realtime live video effects made with TouchDesigner for RIN at Splash! 2022",
        altDe: "Mit TouchDesigner erstellte Echtzeitvideoeffekte für RIN beim Splash! Festival 2022",
      },
      {
        type: "loop",
        url: "https://lichtgst.uber.space/videoLoops/projects/rin-live-22/lichtgestalten-RIN-2022-UpInSmoke-live.mp4",
        aspect: "1.77777778",
        altEn: "Artist being keyed out in realtime on a LED video wall, RIN live 2022",
        altDe: "Ein Künstler wird in Echtzeit freigestellt, RIN live 2022",
      },
      {
        type: "image",
        url: "https://lichtgst.uber.space/photos/projects/rin-live-22/lichtgestalten-rin-live-22-20.jpg",
        altEn: "Realtime visuals for RIN live at Splash! Festival 2022",
        altDe: "Echtzeitvideoeffekte für RIN live beim Splash! Festival 2022",
      },
      {
        type: "image",
        url: "https://lichtgst.uber.space/photos/projects/rin-live-22/lichtgestalten-rin-live-22-18.jpg",
        altEn: "Realtime live video effects made with TouchDesigner for RIN live 2022",
        altDe: "Mit TouchDesigner erstellte Echtzeitvideoeffekte für RIN Live 2022",
      },
      {
        type: "loop",
        url: "https://lichtgst.uber.space/videoLoops/projects/rin-live-22/lichtgestalten-RIN-2022-live.mp4",
        aspect: "1.77777778",
        altEn: "Realtime live visuals made with TouchDesigner for RIN live 2022",
        altDe: "Mit TouchDesigner erstellte Echtzeitvisuals für RIN Live 2022",
      },
      {
        type: "image",
        url: "https://lichtgst.uber.space/photos/projects/rin-live-22/lichtgestalten-rin-live-22-7.jpg",
        altEn: "RIN headlining live at Splash! Festival 2022",
        altDe: "RIN live beim Splash! Festival 2022",
      },
      {
        type: "image",
        url: "https://lichtgst.uber.space/photos/projects/rin-live-22/lichtgestalten-rin-live-22-22.jpg",
        altEn: "RIN live visual effects made with TouchDesigner",
        altDe: "Mit TouchDesigner erstellte Live visuals für RIN",
      },
      {
        type: "loop",
        url: "https://lichtgst.uber.space/videoLoops/projects/rin-live-22/lichtgestalten-RIN-2022-Alien-live.mp4",
        aspect: "1.77777778",
        altEn: "Artist being keyed out in realtime on a LED video wall, RIN live 2022",
        altDe: "Ein Künstler wird in Echtzeit freigestellt, RIN live 2022",
      },
      {
        type: "image",
        url: "https://lichtgst.uber.space/photos/projects/rin-live-22/lichtgestalten-rin-live-22-12.jpg",
        altEn: "RIN live video content in Bietigheim-Bissingen",
        altDe: "RIN live visuals in Bietigheim-Bissingen",
      },
      {
        type: "image",
        url: "https://lichtgst.uber.space/photos/projects/rin-live-22/lichtgestalten-rin-live-22-19.jpg",
        altEn: "Realtime segmentation mask for RIN at Frauenfeld 2022",
        altDe: "Echtzeit Keying Effekt für RIN beim Frauenfeld 2022",
      },
      {
        type: "loop",
        url: "https://lichtgst.uber.space/videoLoops/projects/rin-live-22/lichtgestalten-RIN-2022-1976-live.mp4",
        aspect: "1.77777778",
        altEn: "Realtime visuals made with TouchDesigner for RIN at Frauenfeld 2022",
        altDe: "Mit TouchDesigner erstellte Visuals für RIN beim Frauenfeld 2022",
      },
      {
        type: "image",
        url: "https://lichtgst.uber.space/photos/projects/rin-live-22/lichtgestalten-rin-live-22-17.jpg",
        altEn: "RIN live in Bietigheim-Bissingen 2022",
        altDe: "RIN live in Bietigheim-Bissingen 2022",
      },
    ],
    descriptionEn:
      "Joining RIN on his headlining festival shows in 2022, we worked together with the stage designers at Studio Clemens Loeffelholz and the motion graphic studio Bewegtbildgrafik on a live show with a focus on a hybrid video setup. By combining pre-rendered and realtime content with a machine learning based realtime segmentation algorithm, we achieved a unique new look of live music visuals. The algorithm identifies the artist through fog and light effects, allowing us to virtually set him on fire or use his silhouette for glass-like effects, trigger fluid simulations or just work with basic color and patterns. Thus every single show had an overall common look, but each performance was unique in its details. We were responsible for the development and programming of the realtime keying, realtime video effects as well as the show operating on site.",
    descriptionDe:
      "Zusammen mit Studio Clemens Loeffelholz und Bewegtbildgrafik, entwickelten wir eine Videoshow für die RIN Festival Shows 2022. Dafür nutzten wir einen auf Machine Learning basierenden Algorithmus, um RIN in Echtzeit vom Hintergrund freizustellen. Dadurch konnten wir zusammen mit den vorgerenderten Inhalten von Bewegtbildgrafik ganz neue Konzepte zur Erstellung von Live Visuals entwickeln. Der Algorithmus erkennt den Künstler trotz Nebel und Lichteffekte, wodurch wir ihn virtuell in Flammen aufgehen lassen, seinen Körper in Glass verwandeln oder als Quelle für Flüssigkeitssimulationen verwenden konnten. Dadurch hatte jede Show eine große visuelle Klammer, war aber dennoch jedesmal einzigartig. Wir waren für die Entwicklung und Programmierung des Echtzeit-Keying-Effekts, der Echtzeit-Videoeffekte sowie der Betreuung und Steuerung der Show vor Ort verantwortlich.",
    info: [
      {
        slugCreditsEn: "Date",
        slugCreditsDe: "Datum",
        values: ["03.06. - 09.09.2022"],
      },
      {
        slugCreditsEn: "Client",
        slugCreditsDe: "Kunde",
        values: [
          "RIN / Der Bomber Der Herzen #https://www.der-bomber-der-herzen.de/artist/rin/",
        ],
      },
      {
        slugCreditsEn: "Lead & Stage Design",
        slugCreditsDe: "Lead & Stage Design",
        values: [
          "Studio Clemens Loeffelholz #https://clemensloeffelholz.com/",
        ],
      },
      {
        slugCreditsEn: "Concept",
        slugCreditsDe: "Konzept",
        values: [
          "Studio Clemens Loeffelholz #https://clemensloeffelholz.com/", 
          "Bewegtbildgrafik #https://bewegtbildgrafik.de/", 
          "lichtgestalten",
        ],
      },
      {
        slugCreditsEn: "Pre-Rendered Motion Design",
        slugCreditsDe: "Pre-Rendered Motion Design",
        values: [
          "Bewegtbildgrafik #https://bewegtbildgrafik.de/",
        ],
      },
      {
        slugCreditsEn: "Realtime Motion Design",
        slugCreditsDe: "Echtzeit Motion Design",
        values: [
          "lichtgestalten",
        ],
      },
      {
        slugCreditsEn: "Tour Lighting Director",
        slugCreditsDe: "Tour Lighting Director",
        values: [
          "Emanuel Jörgner",
        ],
      },
      {
        slugCreditsEn: "Operating",
        slugCreditsDe: "Operating",
        values: [
          "lichtgestalten",
        ],
      },
      {
        slugCreditsEn: "Photos / Video",
        slugCreditsDe: "Fotos / Video",
        values: [
          "Sebastian Huber #https://www.sebastianstefanhuber.com/",
          "Paul Gärtner #https://www.paulgärtner.com/",
          "Bitte Einen Film #https://bitteeinenfilm.com/",
        ],
      },
      {
        slugCreditsEn: "Festivals",
        slugCreditsDe: "Festivals",
        values: [
          "Rock Am Ring", 
          "Rock Im Park", 
          "splash!", 
          "Frauenfeld", 
          "and many more...",
        ],
      },
    ],
    tags: ["artDirection", "animation", "realtime"],
  },
  //project start
  {
    shortTitle: "test",
    title: "vsquared AGM 2022 | Munich",
    slug: "vsquared-agm-2022",
    imageURL:
      "https://lichtgst.uber.space/photos/projects/vsquared-agm-22/lichtgestalten-vsquared-agm-22-1.jpg",
    imageTitle: "vsquared AGM 2022 | Munich",
    galleryContent:[
      {
        type: "image",
        url: "https://lichtgst.uber.space/photos/projects/vsquared-agm-22/lichtgestalten-vsquared-agm-22-1.jpg",
        altEn: "Video installation at Blitz Club, Munich",
        altDe: "Videoinstallation im Blitz Club, München",
      },
      {
        type: "image",
        url: "https://lichtgst.uber.space/photos/projects/vsquared-agm-22/lichtgestalten-vsquared-agm-22-3.jpg",
        altEn: "Immersive video installation at the Blitz Club in Munich",
        altDe: "Immersive Videokunst im Blitz Club, München",
      },
      {
        type: "image",
        url: "https://lichtgst.uber.space/photos/projects/vsquared-agm-22/lichtgestalten-vsquared-agm-22-7.jpg",
        altEn: "vsquared AGM 22 at Blitz Club, München",
        altDe: "vsquared Hauptversammlung im Blitz Club, München",
      },
      {
        type: "image",
        url: "https://lichtgst.uber.space/photos/projects/vsquared-agm-22/lichtgestalten-vsquared-agm-22-5.jpg",
        altEn: "Immersive video art at the Blitz Club in Munich",
        altDe: "Immersive Videoinstallation im Blitz Club, München",
      },
      {
        type: "loop",
        url: "https://lichtgst.uber.space/videoLoops/projects/vsquared-agm-22/lichtgestalten-vsquared-agm-22-mappingBlitzClub.mp4",
        aspect: "1.77777778",
        altEn: "3D video mapping on acoustic panels designed by Simon Vorhammer at Blitz Club, Munich",
        altDe: "3D-Videoprojektion auf Akustikpanele von Simon Vorhammer im Blitz Club, München",
      },
      {
        type: "image",
        url: "https://lichtgst.uber.space/photos/projects/vsquared-agm-22/lichtgestalten-vsquared-agm-22-2.jpg",
        altEn: "Video installation at Blitz Club, Munich",
        altDe: "Videoinstallation im Blitz Club, München",
      },
      {
        type: "image",
        url: "https://lichtgst.uber.space/photos/projects/vsquared-agm-22/lichtgestalten-vsquared-agm-22-4.jpg",
        altEn: "Immersive video art at the Blitz Club in Munich",
        altDe: "Immersive Videoinstallation im Blitz Club, München",
      },
    ],
    descriptionEn:
      "vsquared are early-stage investors in deep-tech companies with an emphasis on quantum and novel computing, green energy, robotics, AI/ML, new space, synthetic biology, and healthcare. For their Annual General Meeting 2022, Safe Agency asked us to convert Munichs well-known Blitz Club into a futuristic 'space ship' to create a unique setting. As vsquared and its shareholders are on this journey together, we aimed to emphasize a sense of community. To achieve this, we created a ultra-wide immersive video installation combining four projectors and a LED wall. This allowed us to tell a story in between the different topics of the agenda, while the keynotes itself where accompanied by subtle projections onto the exceptional acoustic panels of the Blitz Club designed by Simon Vorhammer.",
    descriptionDe:
      "vsquared sind Deep-Tech-Investoren mit Schwerpunkten auf den Themen Quantencomputer, Green Energy, Robotik, AI/ML, Raumfahrt, synthetischer Biologie und Gesundheit. Zu ihrer Hauptversammlung 2022 wurden wir von Safe Agency damit beauftragt, den Münchner Blitz Club in ein futuristisches 'Raumschiff' zu verwandeln. Um das Gefühl einer gemeinsamen Reise zu erschaffen, kombinierten wir vier Projektoren und eine LED-Wand zu einer raumgreifenden, immersiven Installation. So konnte zwischen den Tagesordnungspunkten eine den Tag überspannende Geschichte erzählt werden. Während der Präsentationen erzeugten atmosphärische Inhalte und Projektionen auf die von Simon Vorhammer entworfenen Akustikpanele eine kosmische Stimmung.",
    info: [
      {
        slugCreditsEn: "Date",
        slugCreditsDe: "Datum",
        values: ["05.05.2022"],
      },
      {
        slugCreditsEn: "Client",
        slugCreditsDe: "Kunde",
        values: [
          "vsquared #https://vsquared.vc/",
        ],
      },
      {
        slugCreditsEn: "Production & Lead",
        slugCreditsDe: "Produktion & Lead",
        values: [
          "Safe Agency #https://www.safeagency.cc/",
        ],
      },
      {
        slugCreditsEn: "Motion Design",
        slugCreditsDe: "Videoanimation",
        values: [
          "Guts N Glory #http://www.gutsnglory.studio/", "lichtgestalten",
        ],
      },
      {
        slugCreditsEn: "Operating & Projection Mapping",
        slugCreditsDe: "Operating & Projektion",
        values: [
          "lichtgestalten",
        ],
      },
      {
        slugCreditsEn: "Photos",
        slugCreditsDe: "Fotos",
        values: [
          "Mali Wychodil #https://www.instagram.com/bob_mali/",
        ],
      },
      {
        slugCreditsEn: "Location",
        slugCreditsDe: "Veranstaltungsort",
        values: [
          "Blitz Club, München #https://www.blitz.club/",
        ],
      },
    ],
    tags: ["artDirection", "animation", "mapping"],
  },
  //project start
  {
    shortTitle: "test",
    title: "1E9 Conference 2021 | Munich",
    slug: "1e9-conference-2021",
    imageURL:
      "https://lichtgst.uber.space/photos/projects/1e9-conference-2021/lichtgestalten-1E9-conference-2021-38.jpg",
    imageTitle: "1E9 Conference 2021 | Munich",
    galleryContent:[
      {
        type: "image",
        url: "https://lichtgst.uber.space/photos/projects/1e9-conference-2021/lichtgestalten-1E9-conference-2021-45.jpg",
        altEn: "Reaction diffusion shader projected on the dome of the Deutsches Museum, Munich",
        altDe: "Generative Videoprojektion auf die Kuppel des ehemaligen Planetariums im Deutschen Museum, München",
      },
      {
        type: "loop",
        url: "https://lichtgst.uber.space/videoLoops/projects/1e9-conference-2021/lichtgestalten-1E9-21-font-clouds1.mp4",
        aspect: "1.77777778",
        altEn: "Ultra-high resolution animations for 1E9 Conference 2021 at Deutsches Museum, Munich",
        altDe: "Hochauflösende Animation für die Kuppelprojektion der 1E9 Confernece 2021 im Deutschen Museum, München",
      },
      {
        type: "image",
        url: "https://lichtgst.uber.space/photos/projects/1e9-conference-2021/lichtgestalten-1E9-conference-2021-12.jpg",
        altEn: "Projection mapping in the former planetarium at the Deutsches Museum, Munich ",
        altDe: "Videokunst, projiziert auf die Kuppel des Planetariums im Deutschen Museum, München",
      },
      {
        type: "image",
        url: "https://lichtgst.uber.space/photos/projects/1e9-conference-2021/lichtgestalten-1E9-conference-2021-43.jpg",
        altEn: "Raymarched shader made with Touchdesigner for the 1E9 Conference 2021 in Munich",
        altDe: "Mit Touchdesigner programmierte Videoanimation für die 1E9 Conference 2021 in München",
      },
      {
        type: "image",
        url: "https://lichtgst.uber.space/photos/projects/1e9-conference-2021/lichtgestalten-1E9-conference-2021-0.jpg",
        altEn: "Stage of the 1E9 Conference 2021 with projection mapping by lichtgestalten at Deutsches Museum, Munich",
        altDe: "Bühne der 1E9 Conference 2021 mit Kuppelprojektion von lichtgestalten im Deutschen Museum, München",
      },
      {
        type: "loop",
        url: "https://lichtgst.uber.space/videoLoops/projects/1e9-conference-2021/lichtgestalten-1E9-21-reactionDiffusion1.mp4",
        aspect: "1.77777778",
        altEn: "Generative animation dome projection at Deutsches Museum, Munich",
        altDe: "Generative Videoprojektion auf der Kuppel des Deutschen Museums, München",
      },
      {
        type: "image",
        url: "https://lichtgst.uber.space/photos/projects/1e9-conference-2021/lichtgestalten-1E9-conference-2021-2.jpg",
        altEn: "Stage of the 1E9 Conference 2021 with projection mapping by lichtgestalten at Deutsches Museum, Munich",
        altDe: "Bühne der 1E9 Conference 2021 mit Kuppelprojektion von lichtgestalten im Deutschen Museum, München",
      },
      {
        type: "image",
        url: "https://lichtgst.uber.space/photos/projects/1e9-conference-2021/lichtgestalten-1E9-conference-2021-35.jpg",
        altEn: "1E9 Conference 2021 at Deutsches Museum, Munich",
        altDe: "1E9 Conference 2021 im Deutschen Museum, München",
      },
      {
        type: "image",
        url: "https://lichtgst.uber.space/photos/projects/1e9-conference-2021/lichtgestalten-1E9-conference-2021-44.jpg",
        altEn: "Video art projected on the dome of the Deutsches Museum, Munich",
        altDe: "Videokunst mit Kuppelprojektion im Deutschen Museum, München",
      },
      {
        type: "image",
        url: "https://lichtgst.uber.space/photos/projects/1e9-conference-2021/lichtgestalten-1E9-conference-2021-39.jpg",
        altEn: "Raymarched shader made with Touchdesigner for the 1E9 Conference 2021 in Munich",
        altDe: "Mit Touchdesigner programmierte Videoanimation für die 1E9 Conference 2021 in München",
      },
    ],
    descriptionEn:
      "1E9 is a magazine, think tank and community revolving around new technologies and their impacts on our lifes. For their third edition of the annual 1E9 Conference - with talks by Vitalik Buterin (Ethereum), Katalin Karikó, Özlem Türeci and Ugur Sahin (BioNTech SE) and many more - we were invited to cover the dome of the former planetarium at the Deutsches Museum in Munich. The event title 'Synthese' (synthesis) and the three main topics 'Web3, NFTs and Metaverse' to 'Breakthroughs in biotechnology' and 'Green growth and system change' served as conceptual starting points for the ultra-high resolution content we created. As there is no projection equipment installed at the dome, we also took care of the technical planning, setup, mapping and operating of the dome projection.",
    descriptionDe:
      '1E9 ist eine Community rund um zukunftsgerichteten Technologie-Journalismus. Vom 22. - 25. November 2021 fand die 1E9 Conference mit Vitalik Buterin (Ethereum), Katalin Karikó, Özlem Türeci und Ugur Sahin (BioNTech SE) u.v.m. zum dritten Mal statt. Als Veranstaltungsort lud das ehemalige Planetarium im Deutschen Museum München zu einer Kuppelprojektion ein. Rund um den Veranstaltungstitel "Synthese" und die drei Hauptthemen "Web3, NFTs und das Metaverse", "Biotechnologie" und "Nachhaltiges Wachstum und Systemveränderung" gestalteten wir hochauflösende Animationen für die Kuppelprojektion. Da das Planetarium im Deutschen Museum momentan nicht mehr mit Projektionstechnik ausgestattet ist, übernahmen wir auch die technische Planung, Installation und Umsetzung der Projektion.',
    info: [
      {
        slugCreditsEn: "Date",
        slugCreditsDe: "Datum",
        values: ["23. - 25.11.2021"],
      },
      {
        slugCreditsEn: "Client",
        slugCreditsDe: "Kunde",
        values: [
          "1E9 #https://1e9.community/",
        ],
      },
      {
        slugCreditsEn: "Production & Lead",
        slugCreditsDe: "Produktion & Lead",
        values: [
          "Safe Agency #https://www.safeagency.cc/",
        ],
      },
      {
        slugCreditsEn: "Live-Video & Stream",
        slugCreditsDe: "Videoproduktion & Stream",
        values: [
          "DropIn-TV #https://dropintv.de/",
        ],
      },
      {
        slugCreditsEn: "Content & Projection Mapping",
        slugCreditsDe: "Inhalte & Projektion",
        values: [
          "lichtgestalten",
        ],
      },
      {
        slugCreditsEn: "Photos",
        slugCreditsDe: "Fotos",
        values: [
          "Sebastian Huber",
        ],
      },
      {
        slugCreditsEn: "Location",
        slugCreditsDe: "Veranstaltungsort",
        values: [
          "Deutsches Museum, München #https://www.deutsches-museum.de/",
        ],
      },
    ],
    tags: ["animation", "mapping", "installation"],
  },
  //project start
  {
    shortTitle: "test",
    title: "Reeperbahn Festival 2021 | Hamburg",
    slug: "reeperbahn-festival-2021",
    imageURL:
      "https://lichtgst.uber.space/photos/projects/reeperbahn-festival-2021/lichtgestalten-reeperbahn-festival-2021-35.jpg",
    imageTitle: "Reeperbahn Festival 2021 | Hamburg",
    galleryContent:[
      {
        type: "image",
        url: "https://lichtgst.uber.space/photos/projects/reeperbahn-festival-2021/lichtgestalten-reeperbahn-festival-2021-21.jpg",
        altEn: "Screen video installation with artwork by Pia Graf at Reeperbahn Festival 2021, Hamburg",
        altDe: "Videoinstallation mit Animation von Pia Graf beim Reeperbahn Festival 2021, Hamburg",
      },
      {
        type: "loop",
        url: "https://lichtgst.uber.space/videoLoops/projects/reeperbahn-festival-2021/lichtgestalten-rbf21-meltingPot-720p.mp4",
        aspect: "1.77777778",
        altEn: "Generative video art installation by lichtgestalten in Hamburg",
        altDe: "Generative Videoinstallation von lichtgestalten in Hamburg",
      },
      {
        type: "image",
        url: "https://lichtgst.uber.space/photos/projects/reeperbahn-festival-2021/lichtgestalten-reeperbahn-festival-2021-19.jpg",
        altEn: "Art installation by lichtgestalten at Reeperbahn Festival 2021, Hamburg",
        altDe: "Kunstinstallation von lichtgestalten beim Reeperbahn Festival 2021, Hamburg",
      },
      {
        type: "image",
        url: "https://lichtgst.uber.space/photos/projects/reeperbahn-festival-2021/lichtgestalten-reeperbahn-festival-2021-17.jpg",
        altEn: "Studio Dumbar keynote at Reeperbahn Festival 2021, Hamburg",
        altDe: "Studio Dumbar Präsentation beim Reeperbahn Festival 2021, Hamburg",
      },
      {
        type: "image",
        url: "https://lichtgst.uber.space/photos/projects/reeperbahn-festival-2021/lichtgestalten-reeperbahn-festival-2021-14.jpg",
        altEn: "Artwork by Braulio Amado in front of video art installation by lichtgestalten in Hamburg",
        altDe: "Kunstwerk von Braulio Amado vor Videoinstallation von lichtgestalten in Hamburg",
      },
      {
        type: "loop",
        url: "https://lichtgst.uber.space/videoLoops/projects/reeperbahn-festival-2021/lichtgestalten-rbf21-clouds-720p.mp4",
        aspect: "1.77777778",
        altEn: "(IN) BETWEEEN Reeperbahn Festival 2021, Hamburg",
        altDe: "(IN) BETWEEEN Reeperbahn Festival 2021, Hamburg",
      },
      {
        type: "image",
        url: "https://lichtgst.uber.space/photos/projects/reeperbahn-festival-2021/lichtgestalten-reeperbahn-festival-2021-15.jpg",
        altEn: "Jamie Wolfe animation at Reeperbahn Festival 2021, Hamburg",
        altDe: "Animation von Jamie Wolfe beim Reeperbahn Festival 2021, Hamburg",
      },
      {
        type: "image",
        url: "https://lichtgst.uber.space/photos/projects/reeperbahn-festival-2021/lichtgestalten-reeperbahn-festival-2021-18.jpg",
        altEn: "Video art installation at Reeperbahn Festival 2021, Hamburg",
        altDe: "Videokunstinstallation beim Reeperbahn Festival 2021, Hamburg",
      },
      {
        type: "image",
        url: "https://lichtgst.uber.space/photos/projects/reeperbahn-festival-2021/lichtgestalten-reeperbahn-festival-2021-22.jpg",
        altEn: "TV screen installation at Reeperbahn Festival 2021, Hamburg",
        altDe: "Bildschirminstallation beim Reeperbahn Festival 2021, Hamburg",
      },
      {
        type: "image",
        url: "https://lichtgst.uber.space/photos/projects/reeperbahn-festival-2021/lichtgestalten-reeperbahn-festival-2021-34.jpg",
        altEn: "Generative video art by lichtgestalten at Reeperbahn Festival 2021, Hamburg",
        altDe: "Generative Videokunst von lichtgestalten beim Reeperbahn Festival 2021 in Hamburg",
      },
    ],
    descriptionEn:
      "As part of the annual Reeperbahn Festival, the exhibition '(IN) BETWEEN' highlights visual design within the field of music. Curated by Bareis + Nicolaus, we were asked to work on a video installation that suits as a canvas for motion design and keynotes, but also fills the room with sufficient ambient light. 15 screens, some of them overlapping, let the spectator decide whether they want to see the artwork as one giant image or dive deep into the details of the artist's brushes and textures. The installation featured artworks and videos by Pia Graf, Jamie Wolfe, Studio Dumbar, Bendik Kaltenborn & Espen Friberg, Lucas Hesse, Lucas Wurmbach, Bareis + Nicolaus and lichtgestalten.",
    descriptionDe:
      'Als Bestandteil des jährlich stattfinden Reeperbahn Festivals, beschäftigt sich die Ausstellung "(IN) BETWEEEN" mit visuellem Design rund um das Thema Musik. Die Kuratoren Bareis + Nicolaus haben uns damit beauftragt, eine Videoinstallation umzusetzen, die sowohl die Darstellung von Videokunst und Präsentationen erlaubt, als auch die gesamte Ausstellung in ein Umgebungslicht hüllt. Mit 15 teilweise überlappenden Bildschirmen gaben wir den Zuschauer*innen die Möglichkeit, die Kunstwerke sowohl im Gesamtbild als auch im Detail mit Pinselstrichen und Texturen zu betrachten. In der Installation wurden Kunstwerke von Pia Graf, Jamie Wolfe, Studio Dumbar, Bendik Kaltenborn & Espen Fridberg, Lucas Hesse, Lucas Wurmbach, Bareis + Nicolaus und lichtgestalten gezeigt.',
    info: [
      {
        slugCreditsEn: "Date",
        slugCreditsDe: "Datum",
        values: ["22. - 25.09.2021"],
      },
      {
        slugCreditsEn: "Client",
        slugCreditsDe: "Kunde",
        values: [
          "Reeperbahn Festival #https://www.reeperbahnfestival.com/en/frontpage/",
        ],
      },
      {
        slugCreditsEn: "Curation and Exhibition Design",
        slugCreditsDe: "Kuration und Ausstellungsgestaltung",
        values: [
          "Bareis + Nicolaus #https://www.bareis-nicolaus.com/",
        ],
      },
      {
        slugCreditsEn: "Video Installation | Concept, Planning and Realisation",
        slugCreditsDe: "Videoinstallation | Konzept, Planung und Umsetzung",
        values: [
          "lichtgestalten #https://lichtgestalten.li/",
        ],
      },
      {
        slugCreditsEn: "Artworks",
        slugCreditsDe: "Animation und Grafik",
        values: [
          "Pia Graf #https://piagraf.com/",
          "Jamie Wolfe #https://www.jamiewolfe.com/",
          "Studio Dumbar #https://studiodumbar.com/",
          "Bendik Kaltenborn & Espen Friberg",
          "Lucas Hesse #https://www.lucas-hesse.de/",
          "Lucas Wurmbach #https://lucaswurmbach.de/",
          "Bareis + Nicolaus #https://www.bareis-nicolaus.com/",
          "lichtgestalten #https://lichtgestalten.li",
        ],
      },
      {
        slugCreditsEn: "Photos",
        slugCreditsDe: "Fotos",
        values: [
          "Lucas Wurmbach #https://lucaswurmbach.de/",
        ],
      },
    ],
    tags: ["animation", "installation"],
  },
  //project start
  {
    shortTitle: "test",
    title: "9ms - Pleats | München",
    slug: "9ms-pleats-muenchen",
    imageURL:
      "https://lichtgst.uber.space/photos/projects/9ms-pleats-muenchen/lichtgestalten-9ms-pleats-projektion-muenchen-1.jpg",
    imageTitle: "9ms - Pleats | Einstein Kultur, München",
    galleryContent:[
      {
        type: "image",
        url: "https://lichtgst.uber.space/photos/projects/9ms-pleats-muenchen/lichtgestalten-9ms-pleats-projektion-muenchen-1.jpg",
        altEn: "Projection mapping at Einstein Kultur, Munich",
        altDe: "Video-Mapping im Einstein Kultur, München",
      },
      {
        type: "image",
        url: "https://lichtgst.uber.space/photos/projects/9ms-pleats-muenchen/lichtgestalten-9ms-pleats-projektion-muenchen-6.jpg",
        altEn: "Audio-reactive video art in Munich",
        altDe: "Audioreaktive Videokunst in München",
      },
      {
        type: "loop",
        url: "https://lichtgst.uber.space/videoLoops/projects/9ms-pleats-muenchen/lichtgestalten-9ms-pleats-muenchen-raul-snippet.mp4",
        aspect: "1.77777778",
        altEn: "9ms - Pleats live video at Einstein Kultur, Munich",
        altDe: "9ms - Pleats Live Video im Einstein Kultur, München",
      },
      {
        type: "image",
        url: "https://lichtgst.uber.space/photos/projects/9ms-pleats-muenchen/lichtgestalten-9ms-pleats-projektion-muenchen-4.jpg",
        altEn: "Random walk shader video mapping at Einstein Kultur, Munich",
        altDe: "Random Walk Shader Video Mapping im Einstein Kultur, München",
      },
      {
        type: "loop",
        url: "https://lichtgst.uber.space/videoLoops/projects/9ms-pleats-muenchen/lichtgestalten-9ms-pleats-muenchen-snippet.mp4",
        aspect: "1.77777778",
        altEn: "9ms Live Snippet",
        altDe: "9ms Live Ausschnit",
      },
      {
        type: "image",
        url: "https://lichtgst.uber.space/photos/projects/9ms-pleats-muenchen/lichtgestalten-9ms-pleats-projektion-muenchen-7.jpg",
        altEn: "Live video projection in Munich",
        altDe: "Live Video-Projektion in München",
      },
      {
        type: "image",
        url: "https://lichtgst.uber.space/photos/projects/9ms-pleats-muenchen/lichtgestalten-9ms-pleats-projektion-muenchen-8.jpg",
        altEn: "audio-reactive GLSL-Shader for 9ms",
        altDe: "audio-reaktiver GLSL-Shader für 9ms",
      },
      {
        type: "image",
        url: "https://lichtgst.uber.space/photos/projects/9ms-pleats-muenchen/lichtgestalten-9ms-pleats-projektion-muenchen-9.jpg",
        altEn: "Light art in Munich",
        altDe: "Lichtkunst in München",
      },
    ],
    descriptionEn:
      'The two drummers Florian König and Simon Popp released their debut-album "9ms - Pleats" on February 26th 2021 via Squama Recordings. For the tracks "Raul", "Kii" and "Tome" we collaborated with director Johannes Brugger to record one-take videos with audio-reactive visuals. The intense and feedback-laden "Raul" was set to distorted squares opening an infinite room vanishing in the dark, while the camera orbits the two drummers at fast pace. The sounds of "Kii" and "Tome" gave some input to the randomly walking lines for an audio-reactive visual which was derived from the original cover artwork of "Pleats" designed by Maximilian Schachtner (dailydialogue.cc).',
    descriptionDe:
      'Am 26. Februar 2021 veröffentlichten die beiden Münchner Schlagzeuger Florian König und Simon Popp ihr Debüt-Album "9ms - Pleats" via Squama Recordings. Zusammen mit dem Regisseur und Kamermann Johannes Brugger arbeiteten wir an Videos zu den Songs "Raul", "Kii" und "Tome". Eine kreiselnde Kamerabewegung und auf das Gewölbe gemappte Projektionen von scheinbar in der Unendlichkeit verschwindenden, verzerrten Quadraten begleiten "Raul", das mit intensiven Rhythmen und vielschichtigen Feedbackschwaden daherkommt. Die Sounds von "Kii" und "Tome" gebe Impulse an die sonst zufälligen Bewegung von wandernden Partikeln, deren Gestaltung dem von Maximilian Schachtner (dailydialogue.cc) gestalteten Cover-Artwork des Albums "Pleats" abgeleitet wurde.',
    info: [
      {
        slugCreditsEn: "Date",
        slugCreditsDe: "Datum",
        values: ["23. - 24.01.2021"],
      },
      {
        slugCreditsEn: "Music",
        slugCreditsDe: "Musik",
        values: [
          "9ms | Florian König, Simon Popp #https://9msmusic.bandcamp.com/album/pleats",
        ],
      },
      {
        slugCreditsEn: "Label",
        slugCreditsDe: "Label",
        values: [
          "Squama Recordings #https://squamarecordings.bandcamp.com/",
        ],
      },
      {
        slugCreditsEn: "Album Artwork",
        slugCreditsDe: "Grafikgestaltung Album",
        values: [
          "Maximilian Schachtner #https://dailydialogue.cc/",
        ],
      },
      {
        slugCreditsEn: "Director, DoP",
        slugCreditsDe: "Regie, Kamera",
        values: [
          "Johannes Brugger #https://vimeo.com/johannesbrugger",
        ],
      },
      {
        slugCreditsEn: "Gimbal Operator",
        slugCreditsDe: "Gimbal Operator",
        values: [
          "Clemens Krüger #https://www.clemenskrueger.com/ABOUT-ME",
        ],
      },
      {
        slugCreditsEn: "1. AC",
        slugCreditsDe: "Kameraassistent",
        values: [
          "Felix Riedelsheimer #http://felix-riedelsheimer.de/",
        ],
      },
      {
        slugCreditsEn: "Animation, Projection Mapping",
        slugCreditsDe: "Animation, Projektion",
        values: [
          "Lichtgestalten",],
      },
    ],
    tags: ["artDirection", "animation", "mapping", "realtime"],
  },
  //project start
  {
    shortTitle: "test",
    title: "Occhio Lightstream | Passau",
    slug: "occhio-lightstream-passau",
    imageURL:
      "https://lichtgst.uber.space/photos/projects/occhio-lightstream-passau/lichtgestalten-occhio-lightstream-passau-projection-8.png",
    imageTitle: "Occhio Lightstream | Fels der Brandung, Passau",
    galleryContent:[
      {
        type: "image",
        url: "https://lichtgst.uber.space/photos/projects/occhio-lightstream-passau/lichtgestalten-occhio-lightstream-passau-projection-8.png",
        altEn: "Video-mapping for Occhio with particle animation",
        altDe: "Occhio Lampe mit projizierten Partikeln",
      },
      {
        type: "image",
        url: "https://lichtgst.uber.space/photos/projects/occhio-lightstream-passau/lichtgestalten-occhio-lightstream-passau-projection-5.png",
        altEn: "Occhio Mito raggio in front of projection mapping",
        altDe: "Occhio Mito raggio vor Videoprojektion",
      },
      {
        type: "image",
        url: "https://lichtgst.uber.space/photos/projects/occhio-lightstream-passau/lichtgestalten-occhio-lightstream-passau-projection-7.png",
        altEn: "Occhio Mito raggio in front of video art",
        altDe: "Occhio Mito raggio vor Videokunst",
      },
      {
        type: "loop",
        url: "https://lichtgst.uber.space/videoLoops/projects/occhio-lightstream-passau/occhio-lightstream-clouds-trailer-sm.mp4",
        aspect: "1.77777778",
        altEn: "GLSL shader for Occhio, Munich",
        altDe: "GLSL-Videoprojektion für Occhio, München",
      },
      {
        type: "image",
        url: "https://lichtgst.uber.space/photos/projects/occhio-lightstream-passau/lichtgestalten-occhio-lightstream-passau-projection-6.png",
        altEn: "Occhio Mito raggio and GLSL shader projection mapping",
        altDe: "Occhio Mito raggio mit GLSL-Shader-Projektion",
      },
      {
        type: "image",
        url: "https://lichtgst.uber.space/photos/projects/occhio-lightstream-passau/lichtgestalten-occhio-lightstream-passau-projection-9.png",
        altEn: "Projection mapping at Fels der Brandung, Passau, with Occhio Mito raggio",
        altDe: "Videoprojektion im Fels der Brandung, Passau, mit Occhio Mito raggio",
      },
      {
        type: "loop",
        url: "https://lichtgst.uber.space/videoLoops/projects/occhio-lightstream-passau/occhio-lightstream-sphere-particles-trailer-sm.mp4",
        aspect: "1.77777778",
        altEn: "GLSL particle system for Occhio, Munich",
        altDe: "GLSL-Partikelanimation für Occhio, München",
      },
      {
        type: "image",
        url: "https://lichtgst.uber.space/photos/projects/occhio-lightstream-passau/lichtgestalten-occhio-lightstream-passau-projection-10.png",
        altEn: "Particle projection mapping and Occhio Mito raggio",
        altDe: "Partikelprojektion mit Occhio Mito raggio",
      },
    ],
    descriptionEn:
      'For Occhio, a Munich-based lighting design company, lichtgestalten created several realtime animations. Mimicking organic structures and movements, these GLSL shader driven animations were programmed in TouchDesigner and projected onto the walls of the "Fels in der Brandung" building located in Passau. The final result was a sureal short film playing with the contrast of the blue hour and the warm light of the Occhio luminaires.',
    descriptionDe:
      'Für den Designleuchten-Hersteller Occhio aus München kreierten lichtgestalten verschiedene generative Animation. Als Kulisse diente der "Fels in der Brandung" in Passau, auf dessen Wände mit in TouchDesigner programmierten GLSL-Shadern projiziert wurde. Diese in Echtzeit generierten Animationen waren an organische Strukturen und Bewegungen angelehnt und erzeugten ein Spiel mit dem Kontrast zwischen den Farben der "Blauen Stunde" und dem warmen Licht der Occhio-Leuchten.',
    info: [
      {
        slugCreditsEn: "Date",
        slugCreditsDe: "Datum",
        values: ["24.09.2020"],
      },
      {
        slugCreditsEn: "Client",
        slugCreditsDe: "Kunde",
        values: [
          "Occhio #https://de.occhio.de/",
        ],
      },
      {
        slugCreditsEn: "Lead",
        slugCreditsDe: "Lead",
        values: [
          "Avantgarde #https://avantgarde.net",
        ],
      },
      {
        slugCreditsEn: "Art Direction, Lighting",
        slugCreditsDe: "Art Direction, Licht",
        values: [
          "Matthias Singer #http://507nm.de",
          "Clemens Von Loeffelholz #https://clemensloeffelholz.com/",
        ],
      },
      {
        slugCreditsEn: "Animation, Projection Mapping",
        slugCreditsDe: "Animation, Projektion",
        values: [
          "Lichtgestalten",],
      },
    ],
    tags: ["animation", "mapping"],
  },
  //project start
  {
    shortTitle: "test",
    title: "Waterfall | Ritter Sport",
    slug: "waterfall-ritter-sport",
    imageURL:
      "https://lichtgst.uber.space/photos/projects/waterfall-ritter-sport/lichtgestalten-ritter-sport-kakao-klasse_1.jpg",
    imageTitle: "Waterfall Ritter Sport",
    galleryContent:[
      {
        type: "image",
        url: "https://lichtgst.uber.space/photos/projects/waterfall-ritter-sport/lichtgestalten-ritter-sport-kakao-klasse_1.jpg",
        altEn: "Waterfall projection mapping at Ritter Sport Store, Berlin",
        altDe: "Wasserfall-Videoprojektion in der Ritter Sport Schokowelt, Berlin",
      },
      {
        type: "loop",
        url: "https://lichtgst.uber.space/videoLoops/projects/waterfall-ritter-sport/lichtgestalten-waterfall-ritter-sport-loop.mp4",
        altEn: "Waterfall projection mapping for Ritter Sport Kakaoklasse",
        altDe: "Wasserfall-Videoprojektion für die Ritter Sport Kakaoklasse",
      },
      {
        type: "video",
        url: "https://vimeo.com/327661457#t=4s",
        aspect: "1.77777778",
        altEn: "Video of waterfall projection mapping at Ritter Sport Store, Berlin",
        altDe: "Video der Wasserfall-Projektion in der Ritter Sport Schokowelt, Berlin",
      },
      {
        type: "image",
        url: "https://lichtgst.uber.space/photos/projects/waterfall-ritter-sport/lichtgestalten-ritter-sport-kakao-klasse_2.jpg",
        altEn: "Ritter Sport Kakaoklasse release event with waterfall projection mapping",
        altDe: "Ritter Sport Kakaoklasse mit Wasserfall-Videoprojektion",
      },
      {
        type: "image",
        url: "https://lichtgst.uber.space/photos/projects/waterfall-ritter-sport/lichtgestalten-ritter-sport-kakao-klasse_3.jpg",
        altEn: "Video art at Ritter Sport Store, Berlin",
        altDe: "Medienkunst in der Ritter Sport Schokowelt, Berlin",
      },
      {
        type: "image",
        url: "https://lichtgst.uber.space/photos/projects/waterfall-ritter-sport/lichtgestalten-ritter-sport-kakao-klasse_4.jpg",
        altEn: "Waterfall projection mapping in Berlin for Ritter Sport",
        altDe: "Wasserfall-Videoprojektion in Berlin für Ritter Sport",
      },
      {
        type: "image",
        url: "https://lichtgst.uber.space/photos/projects/waterfall-ritter-sport/lichtgestalten-ritter-sport-kakao-klasse_5.jpg",
        altEn: "Ritter Sport flagshipstore with waterfall video mapping",
        altDe: "Ritter Sport Schokowelt mit Wasserfall-Projektion",
      },
      {
        type: "image",
        url: "https://lichtgst.uber.space/photos/projects/waterfall-ritter-sport/lichtgestalten-ritter-sport-kakao-klasse_6.jpg",
        altEn: "Projection mapping for Ritter Sport Kakaoklasse",
        altDe: "Videoprojektion für die Ritter Sport Kakaoklasse",
      },
    ],
    descriptionEn:
      "lichtgestalten were invitied by Brad Rabbit to realize a waterfall projection mapping for the release of Ritter Sport’s new „Kakao-Klasse“ chocolates. Spanning two floors in the Ritter Sport flagshipstore, we used several projectors to create a seamless waterfall. The waterfall interacted with the square elements mounted to the wall, featuring the artworks of the new chocolate classes. The two escelators right in front of the „screen“ and few possibilities to place the projectors made this mapping quite a challenge, which was overcome by planning, modelling and previsualising everything in 3D beforehand.",
    descriptionDe:
      "Für die Veröffentlichung der 'Kakao-Klasse' von Ritter Sport wurden wir von Brad Rabbit beauftragt, den Lichthof der Ritter Sport Schokowelt in Berlin mit einem projizierten Wasserfall in einen Dschungel zu verwandeln. Die Projektion erstreckte sich über zwei Stockwerke und integrierte vorhandene architektonische Elemente als Kollisionsobjekte der projizierten Flüssigkeitssimulation. Um die komplexen räumlichen Gegebenheiten bei der Umsetzung zu berücksichtigen, bauten wir das Gebäude virtuell nach und konnten so eine problemlose Umsetzung ohne Überraschungen planen.",
    info: [
      {
        slugCreditsEn: "Date",
        slugCreditsDe: "Datum",
        values: ["02.02.2019"],
      },
      {
        slugCreditsEn: "Client",
        slugCreditsDe: "Kunde",
        values: [
          "Brad Rabbit",
          "Bareis-Nicolaus #https://www.bareis-nicolaus.com",
        ],
      },
      {
        slugCreditsEn: "Technical Planning, Hardware, Projection Mapping",
        slugCreditsDe: "Technische Planung, Installation, Mapping",
        values: [
          "Lichtgestalten",
        ],
      },
      {
        slugCreditsEn: "Animation",
        slugCreditsDe: "Animation",
        values: [
          "Gero Eckhardt",
        ],
      },
    ],
    tags: ["artDirection", "mapping", "installation"],
  },
  //project start
  {
    shortTitle: "test",
    title: "Mercedes Benz | CASE",
    slug: "mercedes-benz-case",
    imageURL:
      "https://lichtgst.uber.space/photos/projects/mercedes-benz-case/mercedes-benz-case-lichtgestalten-3.jpg",
    imageTitle: "Mercedes Benz CASE",
    galleryContent:[
      {
        type: "video",
        url: "https://vimeo.com/309614930",
        aspect: "2.253703",
        altEn: "Mercedes Benz CASE projection mapping",
        altDe: "Mercedes Benz CASE Projektion und Mapping",
      },
      {
        type: "image",
        url: "https://lichtgst.uber.space/photos/projects/mercedes-benz-case/mercedes-benz-case-lichtgestalten-3.jpg",
        altEn: "Mercedes Benz CASE rear projection mapping",
        altDe: "Rückprojektion für Mercedes Benz CASE",
      },
      {
        type: "image",
        url: "https://lichtgst.uber.space/photos/projects/mercedes-benz-case/mercedes-benz-case-lichtgestalten-1.jpg",
        altEn: "Mercedes Benz CASE film set mapping",
        altDe: "Videoprojektion für Mercedes Benz CASE Werbedreh",
      },
      {
        type: "image",
        url: "https://lichtgst.uber.space/photos/projects/mercedes-benz-case/mercedes-benz-case-lichtgestalten-2.jpg",
        altEn: "Rear projection film set in Stuttgart",
        altDe: "Rückprojektion für ein Film-Set in Stuttgart",
      },
      {
        type: "image",
        url: "https://lichtgst.uber.space/photos/projects/mercedes-benz-case/mercedes-benz-case-lichtgestalten-5.jpg",
        altEn: "Rear projection film set for Mercedes Benz",
        altDe: "Rückprojektion für ein Film-Set von Mercedes Benz",
      },
      {
        type: "image",
        url: "https://lichtgst.uber.space/photos/projects/mercedes-benz-case/mercedes-benz-case-lichtgestalten-4.jpg",
        altEn: "Projection mapping film set in Stuttgart",
        altDe: "Projection Mapping für Filmdreh in Stuttgart",
      },
    ],
    descriptionEn:
      "A boy dreams about the future of mobility. To help him visualise this dream, we were invited by '5ter Stock' to set up 3 projectors and augment his room. Premiered at CES Las Vegas 2019.",
    descriptionDe:
      "Mit einer Rückprojektion setzten wir für '5ter Stock' ein virtuelles Filmset um. So konnten die Wände eines Kinderzimmers zur Leinwand fantasievoller Traumsequenzen werden. Produziert für die CES Las Vegas 2019.",
    info: [
      {
        slugCreditsEn: "Date",
        slugCreditsDe: "Datum",
        values: ["02.02.2019"],
      },
      {
        slugCreditsEn: "Client",
        slugCreditsDe: "Kunde",
        values: [
          "5ter Stock #https://www.5terstock.de/",
          "Mercedes-Benz",
        ],
      },
      {
        slugCreditsEn: "Animation",
        slugCreditsDe: "Animation",
        values: [
          "Bewegtbildgrafik #https://bewegtbildgrafik.de/",
        ],
      },
      {
        slugCreditsEn: "Projection Mapping",
        slugCreditsDe: "Projektion, Mapping",
        values: [
          "Lichtgestalten",
        ],
      },
    ],
    tags: ["mapping"],
  },
  //project start
  {
    shortTitle: "test",
    title: "OGLF step sequencer | ABK",
    slug: "oglf-step-sequencer",
    imageURL:
      "https://lichtgst.uber.space/photos/projects/oglf-step-sequencer/OGLF_30.jpg",
    imageTitle: "OGLF step sequencer cover image",
    galleryContent:[
      {
        type: "image",
        url: "https://lichtgst.uber.space/photos/projects/oglf-step-sequencer/OGLF_30.jpg",
        altEn: "Interactive step sequencer with realtime projection mapping at ABK, Stuttgart",
        altDe: "Interaktiver Step Sequencer mit Echtzeit-Videoprojektion an der ABK, Stuttgart",
      },
      {
        type: "image",
        url: "https://lichtgst.uber.space/photos/projects/oglf-step-sequencer/OGLF_27.jpg",
        altEn: "Projection mapping on gauze cube",
        altDe: "Projektion auf einen Würfel",
      },
      {
        type: "image",
        url: "https://lichtgst.uber.space/photos/projects/oglf-step-sequencer/OGLF_24.jpg",
        altEn: "Interactive step sequencer at ABK, Stuttgart",
        altDe: "Interaktiver Step Sequencer an der ABK, Stuttgart",
      },
      {
        type: "image",
        url: "https://lichtgst.uber.space/photos/projects/oglf-step-sequencer/OGLF_25.jpg",
        altEn: "Projcetion Mapping at ABK, Stuttgart",
        altDe: "Videoprojektion an der ABK, Stuttgart",
      },
      {
        type: "image",
        url: "https://lichtgst.uber.space/photos/projects/oglf-step-sequencer/OGLF_32.jpg",
        altEn: "Full white projection on cube",
        altDe: "Weiße Projektion auf Würfel",
      },
      {
        type: "image",
        url: "https://lichtgst.uber.space/photos/projects/oglf-step-sequencer/OGLF_31.jpg",
        altEn: "OGLF cube projection mapping",
        altDe: "OGLF Würfel mit Videoprojektion",
      },
      {
        type: "image",
        url: "https://lichtgst.uber.space/photos/projects/oglf-step-sequencer/OGLF_28.jpg",
        altEn: "Cube projection mapping with interactive animations",
        altDe: "Projektion auf Würfel mit interaktiven Animationen",
      },
      {
        type: "image",
        url: "https://lichtgst.uber.space/photos/projects/oglf-step-sequencer/OGLF_29.jpg",
        altEn: "Gauze projection mapping with grid animations",
        altDe: "Videoprojektion mit Netzstruktur",
      },
      {
        type: "image",
        url: "https://lichtgst.uber.space/photos/projects/oglf-step-sequencer/OGLF_26.jpg",
        altEn: "Interactive step sequencer with mapped animations",
        altDe: "Interaktiver Step Sequencer mit projizierten Animationen",
      },
      {
        type: "image",
        url: "https://lichtgst.uber.space/photos/projects/oglf-step-sequencer/OGLF_35.jpg",
        altEn: "Projection mapping on gauze cube",
        altDe: "Videoprojektion auf Würfel mit interaktiven Animationen",
      },
    ],
    descriptionEn:
      "Together with graphic designer Christian Nicolaus we extended our installation XXO with visual elements. Following the core principles of a classic step-sequencer, the installation allowed 8 steps with 8 audiovisual-layers, using Max/MSP, Ableton and Resolume to achieve a flexible setup. The triggered graphics were combined with an atmospheric animation on the two semi-transparent projection screens.",
    descriptionDe:
      "Zusammen mit dem Kommunikationsdesigner Christian Nicolaus erweiterten wir unsere Installation 'XXO' mit visuellen Elementen. Angelehnt an einen klassischen Step Sequencer, konnten im OGLF Step Sequencer mit 8 Steps und 8 audiovisellen Ebenen interaktiv Musik und Animationen ausgelöst werden. Verwendet wurden dafür Max/MSP, Ableton und Resolume sowie eine Würfelkonstruktion mit halbtransparenten Leinwänden.",
    info: [
      {
        slugCreditsEn: "Date",
        slugCreditsDe: "Datum",
        values: ["03.07.2018"],
      },
      {
        slugCreditsEn: "Graphic Concept, Motion Design",
        slugCreditsDe: "Grafikkonzept, Animation",
        values: [
          "Bareis-Nicolaus #https://www.bareis-nicolaus.com",
        ],
      },
      {
        slugCreditsEn: "Interactive Concept, Programming & Installation",
        slugCreditsDe: "Interaktives Konzept, Programmierung & Installation",
        values: [
          "Lichtgestalten",
        ],
      },
    ],
    tags: ["realtime"],
  },
  //project start
  {
    shortTitle: "test",
    title: "lichtGestalten | Stadtpalais Stuttgart",
    slug: "lichtgestalten-stadtpalais-stuttgart",
    imageURL:
      "https://lichtgst.uber.space/photos/projects/lichtgestalten-stadtpalais-stuttgart/lichtgestalten-stadtpalais-stuttgart-2.jpg",
    imageTitle: "testBild",
    galleryContent:[
      {
        type: "image",
        url: "https://lichtgst.uber.space/photos/projects/lichtgestalten-stadtpalais-stuttgart/lichtgestalten-stadtpalais-stuttgart-2.jpg",
        altEn: "Stadtpalais Stuttgart opening with LED light sculptures",
        altDe: "LED-Leuchtskulpturen bei der Eröffnung des Stadtpalais Stuttgart",
      },
      {
        type: "image",
        url: "https://lichtgst.uber.space/photos/projects/lichtgestalten-stadtpalais-stuttgart/lichtgestalten-stadtpalais-stuttgart-1.jpg",
        altEn: "LED light sculpture sitting at the entrance of Stadtpalais Stuttgart",
        altDe: "Eine Leuchtskulptur sitzt am Eingang des Stadtpalais Stuttgart",
      },
      {
        type: "image",
        url: "https://lichtgst.uber.space/photos/projects/lichtgestalten-stadtpalais-stuttgart/lichtgestalten-stadtpalais-stuttgart-5.jpg",
        altEn: "LED light sculpture breakdancing in front of Stadtpalais Stuttgart",
        altDe: "Eine Leuchtskulptur macht Breakdance vor dem Stadtpalais Stuttgart",
      },
      {
        type: "image",
        url: "https://lichtgst.uber.space/photos/projects/lichtgestalten-stadtpalais-stuttgart/lichtgestalten-stadtpalais-stuttgart-6.jpg",
        altEn: "View of the back of the Stadtpalais Stuttgart with LED light sculptures",
        altDe: "Rückansicht des Stadtpalais Stuttgart mit LED-Leuchtskulpturen",
      },
      {
        type: "image",
        url: "https://lichtgst.uber.space/photos/projects/lichtgestalten-stadtpalais-stuttgart/lichtgestalten-stadtpalais-stuttgart-7.jpg",
        altEn: "Light sculptures public installation build for the opening of the Stadtpalais Stuttgart",
        altDe: "Kunstinstallation mit leuchtenden Figuren zur Eröffnung des Stadtpalais Stuttgart",
      },
      {
        type: "image",
        url: "https://lichtgst.uber.space/photos/projects/lichtgestalten-stadtpalais-stuttgart/lichtgestalten-stadtpalais-stuttgart-8.jpg",
        altEn: "LED light sculpture meditating at the Stadtpalais Stuttgart",
        altDe: "Eine Leuchtskulptur meditiert vor dem Stadtpalais Stuttgart",
      },
      {
        type: "image",
        url: "https://lichtgst.uber.space/photos/projects/lichtgestalten-stadtpalais-stuttgart/lichtgestalten-stadtpalais-stuttgart-9.jpg",
        altEn: "Light sculptures by daylight in front of the Stadtpalais Stuttgart",
        altDe: "Leuchtskulpturen bei Tag vor dem Stadtpalais Stuttgart",
      },
      {
        type: "image",
        url: "https://lichtgst.uber.space/photos/projects/lichtgestalten-stadtpalais-stuttgart/lichtgestalten-stadtpalais-stuttgart-10.jpg",
        altEn: "LED light sculptures at the entrance of the Stadtpalais Stuttgart",
        altDe: "Leuchtskulpturen am Eingang des Stadtpalais Stuttgart",
      },
      {
        type: "image",
        url: "https://lichtgst.uber.space/photos/projects/lichtgestalten-stadtpalais-stuttgart/lichtgestalten-stadtpalais-stuttgart-11.jpg",
        altEn: "Back entrance of the Stadtpalais Stuttgart",
        altDe: "Hintereingang des Stadtpalais Stuttgart mit Lichtinstallation",
      },
      {
        type: "image",
        url: "https://lichtgst.uber.space/photos/projects/lichtgestalten-stadtpalais-stuttgart/lichtgestalten-stadtpalais-stuttgart-13.jpg",
        altEn: "LED light sculpture at the Stadtpalais Stuttgart",
        altDe: "LED-Lichtinstallation am Stadtpalais Stuttgart",
      },
      {
        type: "image",
        url: "https://lichtgst.uber.space/photos/projects/lichtgestalten-stadtpalais-stuttgart/lichtgestalten-stadtpalais-stuttgart-14.jpg",
        altEn: "Public light art installation with LED light sculptures at night in Stuttgart",
        altDe: "Leuchtskulptur bei Nacht an der Fassade des Stadtpalais Stuttgart",
      },
      {
        type: "image",
        url: "https://lichtgst.uber.space/photos/projects/lichtgestalten-stadtpalais-stuttgart/lichtgestalten-stadtpalais-stuttgart-15.jpg",
        altEn: "Stadtpalais Stuttgart at night with lichtgestalten",
        altDe: "Stadtpalais Stuttgart bei Nacht mit den lichtgestalten",
      },
      {
        type: "image",
        url: "https://lichtgst.uber.space/photos/projects/lichtgestalten-stadtpalais-stuttgart/lichtgestalten-stadtpalais-stuttgart-16.jpg",
        altEn: "Stadtpalais Stuttgart opening installation by lichtgestalten",
        altDe: "Lichtinstallation zur Eröffnung des Stadtpalais Stuttgart von lichtgestalten",
      },
      {
        type: "image",
        url: "https://lichtgst.uber.space/photos/projects/lichtgestalten-stadtpalais-stuttgart/lichtgestalten-stadtpalais-stuttgart-17.jpg",
        altEn: "Stadtpalais Stuttgart LED light figure installation by lichtgestalten",
        altDe: "Lichtkunst am Stadtpalais Stuttgart mit LED-Lichtskulpturen",
      },
      {
        type: "image",
        url: "https://lichtgst.uber.space/photos/projects/lichtgestalten-stadtpalais-stuttgart/lichtgestalten-stadtpalais-stuttgart-18.jpg",
        altEn: "LED light figure couple",
        altDe: "Lichtskulptur-Pärchen",
      },
      {
        type: "image",
        url: "https://lichtgst.uber.space/photos/projects/lichtgestalten-stadtpalais-stuttgart/lichtgestalten-stadtpalais-stuttgart-3.jpg",
        altEn: "LED light sculpture climbing at Stadtpalais Stuttgart",
        altDe: "Eine Lichtskulptur klettert auf das Stadtpalais Stuttgart",
      },
      {
        type: "image",
        url: "https://lichtgst.uber.space/photos/projects/lichtgestalten-stadtpalais-stuttgart/lichtgestalten-stadtpalais-stuttgart-4.jpg",
        altEn: "Stadtpalais Stuttgart LED light figure",
        altDe: "Lichtskulptur am Stadtpalais Stuttgart",
      }
    ],
    descriptionEn:
      "‚Lichtgestalten‘ were commissioned to create an installation for the opening festival of the Stadtpalais Stuttgart, a museum dedicated to the city of Stuttgart and its history. 60 illuminated figures were installed on the facade to show a playful way of the city’s people reconquering this prestigious building. The ‚lichtGestalten‘ figures depict urban life in abstract ways, mimicking social interactions and the socially rejected side by side.",
    descriptionDe:
      "Für das Eröffnungsfestival des Stadtpalais Stuttgart bauten wir über 60 Lichtgestalten, die für 4 Wochen an der Fassade des Stadtpalais als Symbol für die Bürger Stuttgarts das Stadtmuseum erkundeten und zurückeroberten. Die wetterfesten, mit LEDs beleuchteten Figuren wurden in verschiedenen Posen angefertigt, um auf eine spielerische Art und Weise das Zusammenleben in einer Stadt wieder zu spiegeln.",
    info: [
      {
        slugCreditsEn: "Date",
        slugCreditsDe: "Datum",
        values: ["13.04. - 30.04.2018"],
      },
      {
        slugCreditsEn: "Client",
        slugCreditsDe: "Kunde",
        values: [
          "Stadtpalais Stuttgart #https://www.stadtpalais-stuttgart.de/",
        ],
      },
      {
        slugCreditsEn: "Idea, Production & Installation",
        slugCreditsDe: "Idee, Produktion & Installation",
        values: [
          "Lichtgestalten",
        ],
      },
      {
        slugCreditsEn: "Technical Consulting",
        slugCreditsDe: "Technische Beratung",
        values: [
          "507nanometer",
        ],
      },
      {
        slugCreditsEn: "Photos",
        slugCreditsDe: "Fotos",
        values: ["Jan Potente #https://www.janpotente.de/"],
      },
    ],
    tags: ["installation"],
  },
  //project start
  {
    shortTitle: "test",
    title: "FC Bayern – Mapping | Allianz Arena",
    slug: "fcbayern-mapping-allianz-arena",
    imageURL:
      "https://lichtgst.uber.space/photos/projects/fcbayern-mapping-allianz-arena/fcb-mapping-allianz-arena-2016-3.jpg",
    imageTitle: "testBild",
    galleryContent:[
      {
        type: "image",
        url: "https://lichtgst.uber.space/photos/projects/fcbayern-mapping-allianz-arena/fcb-mapping-allianz-arena-2016-3.jpg",
        altEn: "Allianz Arena projection mapping for FC Bayern München",
        altDe: "Videoprojektion in der Allianz Arena für den FC Bayern München",
      },
      {
        type: "image",
        url: "https://lichtgst.uber.space/photos/projects/fcbayern-mapping-allianz-arena/fcb-mapping-allianz-arena-2016-1.jpg",
        altEn: "FC Bayern München logo projected on the pitch of Allianz Arena",
        altDe: "Logo des FC Bayern München als Projektion auf dem Spielfeld der Allianz Arena",
      },
      {
        type: "image",
        url: "https://lichtgst.uber.space/photos/projects/fcbayern-mapping-allianz-arena/fcb-mapping-allianz-arena-2016-2.jpg",
        altEn: "DFB trophy and Meisterschale projection mapping at Allianz Arena München",
        altDe: "Projektion von DFB-Pokal und Meisterschale in der Allianz Arena München",
      },
      {
        type: "image",
        url: "https://lichtgst.uber.space/photos/projects/fcbayern-mapping-allianz-arena/fcb-mapping-allianz-arena-2016-4.jpg",
        altEn: "Projection mapping at Allianz Arena for FC Bayern München",
        altDe: "Projection-Mapping in der Allianz Arena für den FC Bayern München",
      },
      {
        type: "image",
        url: "https://lichtgst.uber.space/photos/projects/fcbayern-mapping-allianz-arena/fcb-mapping-allianz-arena-2016-5.jpg",
        altEn: "Video mapping at Allianz Arena for FC Bayern München",
        altDe: "Video-Mapping in der Allianz Arena für den FC Bayern München",
      },
      {
        type: "image",
        url: "https://lichtgst.uber.space/photos/projects/fcbayern-mapping-allianz-arena/fcb-mapping-allianz-arena-2016-6.jpg",
        altEn: "Projection mapping christmas show at Allianz Arena for FC Bayern München",
        altDe: "Videoprojektion zu Weihnachten in der Allianz Arena für den FC Bayern München",
      },
      {
        type: "image",
        url: "https://lichtgst.uber.space/photos/projects/fcbayern-mapping-allianz-arena/fcb-mapping-allianz-arena-2016-7.jpg",
        altEn: "FC Bayern München logo projection mapping",
        altDe: "FC Bayern München Logo als Videoprojektion",
      },
      {
        type: "image",
        url: "https://lichtgst.uber.space/photos/projects/fcbayern-mapping-allianz-arena/fcb-mapping-allianz-arena-2016-8.jpg",
        altEn: "FC Bayern München Danke christmas video mapping show at Allianz Arena",
        altDe: "Der FC Bayern München sagt mit Video-Mapping 'Danke' zu Weihnachten in der Allianz Arena",
      },
      {
        type: "image",
        url: "https://lichtgst.uber.space/photos/projects/fcbayern-mapping-allianz-arena/fcb-mapping-allianz-arena-2016-9.jpg",
        altEn: "FC Bayern München logo video mapping",
        altDe: "FC Bayern München Logo Videomapping",
      },
      {
        type: "image",
        url: "https://lichtgst.uber.space/photos/projects/fcbayern-mapping-allianz-arena/fcb-mapping-allianz-arena-2016-10.jpg",
        altEn: "FC Bayern München logo projected on pitch of Allianz Arena",
        altDe: "FC Bayern München Logo auf das Spielfeld der Allianz Arena projiziert",
      },
      {
        type: "image",
        url: "https://lichtgst.uber.space/photos/projects/fcbayern-mapping-allianz-arena/fcb-mapping-allianz-arena-2016-11.jpg",
        altEn: "FC Bayern München video mapping at Allianz Arena",
        altDe: "FC Bayern München Videoprojektion in der Allianz Arena",
      },
      {
        type: "image",
        url: "https://lichtgst.uber.space/photos/projects/fcbayern-mapping-allianz-arena/fcb-mapping-allianz-arena-2016-12.jpg",
        altEn: "FC Bayern München video mapping projector rig",
        altDe: "FC Bayern München Projektion Making Of",
      }
    ],
    descriptionEn: 
      "After the last game of the year 2016, FC Bayern München had something special in mind to say ‚Danke‘ to their fans and supporters. A diverse projection mapping with 3D illusions covering the pitch in a retrospect of the last season and won trophies while celebrating the current team. Slowing down for a performance of the Tölzer Knabenchor in a christmas landscape after a short glimpse into Bavarian culture. And finally a grand ‚Danke‘ from the club and the team to the fan culture and their supporters.",
    descriptionDe:
      "Nach dem letzten Spieltag 2016, kurz vor Weihnachten, bedankte sich der FC Bayern München mit einem Videomapping auf der gesamten Fläche des Spielfelds der Allianz Arena bei seinen Fans für ihre Unterstützung. Mit Projektionen von Spielszenen der vergangenen Saison, der gewonnen Pokale, 3D-Illusionen, einem Auftritt des Tölzer Knabenchors bis zum großen Finale mit der gesamten Mannschaft in Mitten eines projizierten, überdimensionalen `Danke` wurden hier die vielseitigen Möglichkeiten eines Mappings unter komplexen Rahmenbedingungen aufgezeigt.",
    info: [
      {
        slugCreditsEn: "Date",
        slugCreditsDe: "Datum",
        values: ["21.12.2016"],
      },
      {
        slugCreditsEn: "Client",
        slugCreditsDe: "Kunde",
        values: [
          "FC Bayern München AG (Executive Board Member: Andreas Jung | Director Media, Digital and Communication: Stefan Mennerich | Head of Events: Doris Kintrup",
        ],
      },
      {
        slugCreditsEn: "Creative Director",
        slugCreditsDe: "Creative Director",
        values: ["gewi - Thomas Gemeinwieser"],
      },
      {
        slugCreditsEn: "Art Director",
        slugCreditsDe: "Art Director",
        values: ["Lichtgestalten - Dominik Schatz, Philipp Kaiser"],
      },
      {
        slugCreditsEn: "Technical Planning / Technical Provider / Hardware",
        slugCreditsDe: "Technical Planning / Technical Provider / Hardware",
        values: ["Wilhelm & Whilhalm Event Technik - Katharina Maercks #https://www.wwet-group.de/"],
      },
      {
        slugCreditsEn: "Executive Director",
        slugCreditsDe: "Executive Director",
        values: ["MUSIC COMPANY Media Productions OHG - Philipp Diehl-Thiele"],
      },
      {
        slugCreditsEn: "Video Mapping / Animations",
        slugCreditsDe: "Video Mapping / Animations",
        values: ["loop light GmbH - Matt Finke, Matthias Etz #https://www.looplight.de/"],
      },
      {
        slugCreditsEn: "Stream / Documentation",
        slugCreditsDe: "Stream / Documentation",
        values: ["MM FILM & VIDEO Manfred Müller GmbH - Gerhard Friedrich"],
      },
      {
        slugCreditsEn: "Composer",
        slugCreditsDe: "Composer",
        values: ["audio video disco – sound solutions - Daniel Requardt #https://audio-video-disco.com/"],
      },
      {
        slugCreditsEn: "Editor",
        slugCreditsDe: "Editor",
        values: ["Monika Stoeger, Helmar Jungmann"],
      },
      {
        slugCreditsEn: "Set Designer",
        slugCreditsDe: "Set Designer",
        values: ["KRAUSS messe & event – Felix Krauss, Katrin Krauss, Michael Holzer"],
      },
      {
        slugCreditsEn: "LED Boards",
        slugCreditsDe: "LED Boards",
        values: ["AIM Sport AG #https://aimsport.com"],
      },
      {
        slugCreditsEn: "Thanks To",
        slugCreditsDe: "Danke",
        values: [
          "Allianz Arena München Gmbh #https://allianz-arena.com/de",
          "Stephan Lehmann",
          "EVISCO AG #https://www.evisco.com/",
          "FC BAYERN Jugendspieler",
          "Tölzer Knabenchor",
          "DO & CO München GmbH #https://www.doco.com/",
          "Joseph Vilsmaier"
        ],
      },
    ],
    tags: ["artDirection", "mapping"],
  },
  //project start
  {
    shortTitle: "test",
    title: "MS Dockville 2016 | Hamburg",
    slug: "ms-dockville-2016-hamburg",
    imageURL:
      "https://lichtgst.uber.space/photos/projects/ms-dockville-2016/lichtgestalten-msdockville-16-Hands1.jpg",
    imageTitle: "testBild",
    galleryContent:[
      {
        type: "image",
        url: "https://lichtgst.uber.space/photos/projects/ms-dockville-2016/lichtgestalten-msdockville-16-Hands1.jpg",
        altEn: "Projection mapping at MS Dockville 2016 in Hamburg",
        altDe: "Projektion auf den GTH Getreidespeicher beim MS Dockville 2016 in Hamburg",
      },
      {
        type: "video",
        url: "https://vimeo.com/472653350",
        aspect: "1.77777778",
      },
      {
        type: "image",
        url: "https://lichtgst.uber.space/photos/projects/ms-dockville-2016/lichtgestalten-msdockville-16-Geburtstag2.jpg",
        altEn: "Projection on large building at MS Dockville 2016 in Hamburg",
        altDe: "Projektionskunst beim MS Dockville 2016 in Hamburg",
      },
      {
        type: "image",
        url: "https://lichtgst.uber.space/photos/projects/ms-dockville-2016/lichtgestalten-msdockville-16-Christo2.jpg",
        altEn: "Projection on large building at MS Dockville 2016 in Hamburg",
        altDe: "Projektionskunst beim MS Dockville 2016 in Hamburg",
      },
      {
        type: "image",
        url: "https://lichtgst.uber.space/photos/projects/ms-dockville-2016/lichtgestalten-msdockville-16-Christo5.jpg",
      },
      {
        type: "image",
        url: "https://lichtgst.uber.space/photos/projects/ms-dockville-2016/lichtgestalten-msdockville-16-Bunt1.jpg",
      },
      {
        type: "image",
        url: "https://lichtgst.uber.space/photos/projects/ms-dockville-2016/lichtgestalten-msdockville-16-Bunt2.jpg",
      },
      {
        type: "image",
        url: "https://lichtgst.uber.space/photos/projects/ms-dockville-2016/lichtgestalten-msdockville-16-Fernrohr2.jpg",
      },
      {
        type: "image",
        url: "https://lichtgst.uber.space/photos/projects/ms-dockville-2016/lichtgestalten-msdockville-16-Fische2.jpg",
      },
      {
        type: "image",
        url: "https://lichtgst.uber.space/photos/projects/ms-dockville-2016/lichtgestalten-msdockville-16-Font1.jpg",
      },
      {
        type: "image",
        url: "https://lichtgst.uber.space/photos/projects/ms-dockville-2016/lichtgestalten-msdockville-16-Klekse2.jpg",
      },
      {
        type: "image",
        url: "https://lichtgst.uber.space/photos/projects/ms-dockville-2016/lichtgestalten-msdockville-16-Konfetti1.jpg",
      },
      {
        type: "image",
        url: "https://lichtgst.uber.space/photos/projects/ms-dockville-2016/lichtgestalten-msdockville-16-Linien1.jpg",
      },
      {
        type: "image",
        url: "https://lichtgst.uber.space/photos/projects/ms-dockville-2016/lichtgestalten-msdockville-16-Raster2.jpg",
      },
      {
        type: "image",
        url: "https://lichtgst.uber.space/photos/projects/ms-dockville-2016/lichtgestalten-msdockville-16-Silo4.jpg",
      },
      {
        type: "image",
        url: "https://lichtgst.uber.space/photos/projects/ms-dockville-2016/lichtgestalten-msdockville-16-Trommeln1.jpg",
      },
    ],
    descriptionEn: 
      "We made our third trip to the MS Dockville Festival, but that doesn’t mean we’re getting tired. For the 10th anniversary of the festival we had a much bigger output than before, creating more than 10 artworks for the Rethespeicher, a 12 minute animation loop for the silo and some atmospheric tree projections on the festivals side itself.",
    descriptionDe:
      "2016 war unser dritter Ausflug nach Hamburg zum MS Dockville Festival - das zum zehnten Mal stattfand und damit ein Jubiläum feierte. Dafür projizierten wir einen 12minütigen Videoloop auf das Silo, über 10 Motive auf den Rethespeicher und setzten einige atmosphärische Projektionen in den Bäumen des Festivalgeländes um.",
    info: [
      {
        slugCreditsEn: "Date",
        slugCreditsDe: "Datum",
        values: ["18. – 21.08.2016"],
      },
      {
        slugCreditsEn: "Client",
        slugCreditsDe: "Kunde",
        values: [
          "Kopf & Steine GmbH #https://www.kopfundsteine.de/",
        ],
      },
      {
        slugCreditsEn: "Production",
        slugCreditsDe: "Produktion",
        values: ["Lichtgestalten"],
      },
      {
        slugCreditsEn: "Desgin",
        slugCreditsDe: "Gestaltung",
        values: [
          "Fabian Friedrich #http://fabianfriedrich.com/",
          "Christian Nicolaus #http://christiannicolaus.de/",
          "Bewegtbildgrafik #http://bewegtbildgrafik.de/",
          "Lichtgestalten",
        ],
      },
      {
        slugCreditsEn: "Animation",
        slugCreditsDe: "Animation",
        values: [
          "Fabian Friedrich #http://fabianfriedrich.com/",
          "Lichtgestalten",
        ],
      },
      {
        slugCreditsEn: "Documentation",
        slugCreditsDe: "Animation",
        values: [
          "Laila Sieber",
          "Lichtgestalten",
        ],
      },
    ],
    tags: ["artDirection", "animation", "mapping"],
  },
  //project start
  {
    shortTitle: "test",
    title: "MLIVE | Berlin",
    slug: "mlive-berlin",
    imageURL:
      "https://lichtgst.uber.space/photos/projects/mlive-berlin/mlive-berlin-2016-lichtgestalten-honne-live2.jpg",
    imageTitle: "testBild",
    galleryContent:[
      {
        type: "image",
        url: "https://lichtgst.uber.space/photos/projects/mlive-berlin/mlive-berlin-2016-lichtgestalten-honne-live2.jpg",
      },
      {
        type: "image",
        url: "https://lichtgst.uber.space/photos/projects/mlive-berlin/mlive-berlin-2016-lichtgestalten-fassadenprojektion2.jpg",
      },
      {
        type: "image",
        url: "https://lichtgst.uber.space/photos/projects/mlive-berlin/mlive-berlin-2016-lichtgestalten-honne-live.jpg",
      },
      {
        type: "image",
        url: "https://lichtgst.uber.space/photos/projects/mlive-berlin/mlive-berlin-2016-lichtgestalten-projection.jpg",
      },
      {
        type: "image",
        url: "https://lichtgst.uber.space/photos/projects/mlive-berlin/mlive-berlin-2016-lichtgestalten-projection2.jpg",
      },
      {
        type: "image",
        url: "https://lichtgst.uber.space/photos/projects/mlive-berlin/mlive-berlin-2016-lichtgestalten-setup.jpg",
      },
      {
        type: "image",
        url: "https://lichtgst.uber.space/photos/projects/mlive-berlin/mlive-berlin-2016-lichtgestalten-stage1.jpg",
      },
      {
        type: "image",
        url: "https://lichtgst.uber.space/photos/projects/mlive-berlin/mlive-berlin-2016-lichtgestalten-tom-misch-3.jpg",
      },
    ],
    descriptionEn: 
      "To kick of its worldwide MLIVE event series, Majestic Casual invited several acts like HONNE, Snakehips or Shir Khan to the Malzfabrik in Berlin. We were responsible for the conception, realisation and live-control of the stage- and light-design. To cover the wide range of musical styles with DJs and bands, we decided to combine projections and lighting which disappear when not in action with black projection surfaces. Furthermore we went for strong backlighting to emphasise the introvert and mystical style of the Majestic Casual brand.",
    descriptionDe:
      "MLIVE, eine weltweite Eventreihe von Majestic Casual, feierte seine Premiere mit einer Veranstaltung in der Malzfabrik Berlin. Mit dabei waren u.a. HONNE, Snakehips, Shir Khan und Tom Misch. Wir entwarfen und setzten die Bühnebeleuchtung um, die sowohl DJs als auch Bands in Szene setzen sollte. Dafür kombinierten wir Projektionen und Leuchten zu einem Hintergrund, der bei Bedarf in der Dunkelheit verschwinden konnte, um den eher introvertierten Stil von Majestic Casual gerecht zu werden.",
    info: [
      {
        slugCreditsEn: "Date",
        slugCreditsDe: "Datum",
        values: ["05.02.2016"],
      },
      {
        slugCreditsEn: "Client",
        slugCreditsDe: "Kunde",
        values: ["Majestic Casual #https://www.youtube.com/user/majesticcasual?gl=DE&hl=de"],
      },
      {
        slugCreditsEn: "Event",
        slugCreditsDe: "Event",
        values: ["Do The Do #https://www.gottadothedo.com/"],
      },
      {
        slugCreditsEn: "Production, Stage Design, Animation & Operating",
        slugCreditsDe: "Produktion, Bühne & Animation",
        values: ["Lichtgestalten"],
      },
    ],
    tags: ["artDirection", "animation"],
  },
  //project start
  {
    shortTitle: "test",
    title: "3D Audio Konzert | Eau Rouge",
    slug: "eau-rouge-3d-audio-concert",
    imageURL:
      "https://lichtgst.uber.space/photos/projects/eau-rouge-3d-audio-concert/lichtgestalten-eau-rouge-3d-audio-concert-6.jpg",
    imageTitle: "testBild",
    galleryContent:[
      {
        type: "image",
        url: "https://lichtgst.uber.space/photos/projects/eau-rouge-3d-audio-concert/lichtgestalten-eau-rouge-3d-audio-concert-6.jpg",
      },
      {
        type: "image",
        url: "https://lichtgst.uber.space/photos/projects/eau-rouge-3d-audio-concert/lichtgestalten-eau-rouge-3d-audio-concert-9.jpg",
      },
      {
        type: "image",
        url: "https://lichtgst.uber.space/photos/projects/eau-rouge-3d-audio-concert/lichtgestalten-eau-rouge-3d-audio-concert-5.jpg",
      },
      {
        type: "image",
        url: "https://lichtgst.uber.space/photos/projects/eau-rouge-3d-audio-concert/lichtgestalten-eau-rouge-3d-audio-concert-8.jpg",
      },
      {
        type: "image",
        url: "https://lichtgst.uber.space/photos/projects/eau-rouge-3d-audio-concert/lichtgestalten-eau-rouge-3d-audio-concert-3.jpg",
      },
      {
        type: "image",
        url: "https://lichtgst.uber.space/photos/projects/eau-rouge-3d-audio-concert/lichtgestalten-eau-rouge-3d-audio-concert-2.jpg",
      },
      {
        type: "image",
        url: "https://lichtgst.uber.space/photos/projects/eau-rouge-3d-audio-concert/lichtgestalten-eau-rouge-3d-audio-concert-10.jpg",
      },
      {
        type: "image",
        url: "https://lichtgst.uber.space/photos/projects/eau-rouge-3d-audio-concert/lichtgestalten-eau-rouge-3d-audio-concert-1.jpg",
      },
    ],
    descriptionEn: 
      "Benedikt Maile and Daniel Schiffner teamed up with the Stuttgart based band Eau Rouge to create and experiment with a rather uncommon live setup at the Hochschule der Medien Stuttgart. 40 speakers and 4 subwoofers were rigged in 3 different heights to enable wave field synthesis, which allows to place audio signals in a virtual spatial environment and experience Eau Rouge 3D. Simply said, you are able to exactly locate where the sound is coming from and single instruments can be placed behind you or rotate around your head for creative and psychedelic effects. To support this omnidirectional experience visually, we first worked out a way to distribute light evenly in the room or place beams in specific locations without an excessive setup. A mirrored hemisphere, heavy fog and 15.000 ANSI-Lumen helped us to achieve this goal. The two audio engineers intended to stay close to classic setups and use the 3D sound capabilities for subtle effects, so we supported the musical climaxes with strobes and backlighting on stage.",
    descriptionDe:
      "Zusammen mit der Stuttgarter Band 'Eau Rouge' realisierten Benedikt Maille und Daniel Schiffner ein experimentelles 3D-Audio-Konzert an der Hochschule der Medien in Stuttgart. 40 Lautsprecher und 4 Subwoofer wurden in verschiedenen Höhen montiert, um ein räumliches Klangerlebnis zu schaffen, bei dem ein Sound scheinbar beliebig im Raum platziert werden kann. Um diese Räumlichkeit auch visuell aufgreifen zu können, projizierten wir mit einem Projektor auf eine spiegelnde Halbkugel, wodurch wir mit einem relativ subtilem Setup räumliche, audioreaktive Animationen umsetzen konnten.",
    info: [
      {
        slugCreditsEn: "Date",
        slugCreditsDe: "Datum",
        values: ["18. - 19.02.2016"],
      },
      {
        slugCreditsEn: "Production",
        slugCreditsDe: "Produktion",
        values: ["Benedikt Maille, Daniel Schiffner"],
      },
      {
        slugCreditsEn: "Music",
        slugCreditsDe: "Musik",
        values: ["Eau Rouge"],
      },
      {
        slugCreditsEn: "Animation & Light Design",
        slugCreditsDe: "Animation & Lichtgestaltung",
        values: ["Lichtgestalten"],
      },
      {
        slugCreditsEn: "Photos",
        slugCreditsDe: "Fotos",
        values: ["Jan Potente #https://www.janpotente.de/"],
      },
    ],
    tags: ["artDirection", "animation"],
  },
  //project start
  {
    shortTitle: "test",
    title: "MS Dockville 2015 | Hamburg",
    slug: "ms-dockville-2015",
    imageURL:
      "https://lichtgst.uber.space/photos/projects/ms-dockville-2015/ms-dockville-2015-lichtgestalten-3d_font.jpg",
    imageTitle: "testBild",
    galleryContent:[
      {
        type: "image",
        url: "https://lichtgst.uber.space/photos/projects/ms-dockville-2015/ms-dockville-2015-lichtgestalten-3d_font.jpg",
      },
      {
        type: "image",
        url: "https://lichtgst.uber.space/photos/projects/ms-dockville-2015/ms-dockville-2015-lichtgestalten-balloon_font.jpg",
      },
      {
        type: "image",
        url: "https://lichtgst.uber.space/photos/projects/ms-dockville-2015/ms-dockville-2015-lichtgestalten-dockville-pipes.jpg",
      },
      {
        type: "image",
        url: "https://lichtgst.uber.space/photos/projects/ms-dockville-2015/ms-dockville-2015-lichtgestalten-grid.jpg",
      },
      {
        type: "image",
        url: "https://lichtgst.uber.space/photos/projects/ms-dockville-2015/ms-dockville-2015-lichtgestalten-anchor-fog.jpg",
      },
      {
        type: "image",
        url: "https://lichtgst.uber.space/photos/projects/ms-dockville-2015/ms-dockville-2015-lichtgestalten-leuchtturm-publikum.jpg",
      },
      {
        type: "image",
        url: "https://lichtgst.uber.space/photos/projects/ms-dockville-2015/ms-dockville-2015-lichtgestalten-line-font-2.jpg",
      },
      {
        type: "image",
        url: "https://lichtgst.uber.space/photos/projects/ms-dockville-2015/ms-dockville-2015-lichtgestalten-lineup.jpg",
      },
      {
        type: "image",
        url: "https://lichtgst.uber.space/photos/projects/ms-dockville-2015/ms-dockville-2015-lichtgestalten-silo-bullauge.jpg",
      },
      {
        type: "image",
        url: "https://lichtgst.uber.space/photos/projects/ms-dockville-2015/ms-dockville-2015-lichtgestalten-silo-bullauge2.jpg",
      },
      {
        type: "image",
        url: "https://lichtgst.uber.space/photos/projects/ms-dockville-2015/ms-dockville-2015-lichtgestalten-silo-fisch1.jpg",
      },
      {
        type: "image",
        url: "https://lichtgst.uber.space/photos/projects/ms-dockville-2015/ms-dockville-2015-lichtgestalten-silo-fisch3.jpg",
      },
      {
        type: "image",
        url: "https://lichtgst.uber.space/photos/projects/ms-dockville-2015/ms-dockville-2015-lichtgestalten-silo-krake2.jpg",
      },
      {
        type: "image",
        url: "https://lichtgst.uber.space/photos/projects/ms-dockville-2015/ms-dockville-2015-lichtgestalten-silo-krake3.jpg",
      },
      {
        type: "image",
        url: "https://lichtgst.uber.space/photos/projects/ms-dockville-2015/ms-dockville-2015-lichtgestalten-silo-leuchtturm1.jpg",
      },
      {
        type: "image",
        url: "https://lichtgst.uber.space/photos/projects/ms-dockville-2015/ms-dockville-2015-lichtgestalten-silo-leuchtturm2.jpg",
      },
      {
        type: "image",
        url: "https://lichtgst.uber.space/photos/projects/ms-dockville-2015/ms-dockville-2015-lichtgestalten-silo-pipe-font.jpg",
      },
      {
        type: "image",
        url: "https://lichtgst.uber.space/photos/projects/ms-dockville-2015/ms-dockville-2015-lichtgestalten-silo-seemann.jpg",
      },
      {
        type: "image",
        url: "https://lichtgst.uber.space/photos/projects/ms-dockville-2015/ms-dockville-2015-lichtgestalten-the_better_the_beer.jpg",
      },
    ],
    descriptionEn: 
      "Our second time projecting at the MS Dockville Festival proved again that this festival is one of our highlights. We had the pleasure to map the 170m x 75m surface of the Rethespeicher, the projection flagship of the MS Dockville 2015, and the 50m tall silo next to it. The silo was our proving ground for a new workflow, as we distorted the content onto the convex shape to fit perfectly to the perspective of the festival audience on the other side of the 200m wide channel, creating the illusion that the silos front is invisible.",
    descriptionDe:
      "Unser zweiter Ausflug zum MS Dockville Festival in Hamburg ermöglichte es uns erneut, den 170x75m großen Rethspeicher mit Motiven zu bespielen. Zudem konnten wir auf dem Silo einen neuen Workflow für die Umsetzung einer 'Trompe-l’œil'-Illusion ausprobieren, damit die zum Festivalgelände laufenden Besucher mit einer virtuell unsichtbaren Silo-Wand und scheinbar dreidimensionalen Motiven auf das Festival eingestimmt werden.",
    info: [
      {
        slugCreditsEn: "Date",
        slugCreditsDe: "Datum",
        values: ["20. - 23.08.2015"],
      },
      {
        slugCreditsEn: "Client",
        slugCreditsDe: "Kunde",
        values: [
          "Kopf & Steine GmbH #https://www.kopfundsteine.de/",
        ],
      },
      {
        slugCreditsEn: "Production",
        slugCreditsDe: "Produktion",
        values: ["Lichtgestalten"],
      },
      {
        slugCreditsEn: "Design",
        slugCreditsDe: "Gestaltung",
        values: [
          "Fabian Friedrich #http://fabianfriedrich.com/",
          "Christian Nicolaus #http://christiannicolaus.de/",
          "Lichtgestalten",
        ],
      },
      {
        slugCreditsEn: "Animation",
        slugCreditsDe: "Animation",
        values: [
          "Fabian Friedrich #http://fabianfriedrich.com/",
          "Lichtgestalten",
        ],
      },
      {
        slugCreditsEn: "Documentation",
        slugCreditsDe: "Dokumentation",
        values: [
          "David Dollmann #http://daviddollmann.com/",
          "Lukas Nicolaus #http://lukasnicolaus.de/",
        ],
      },
    ],
    tags: ["artDirection", "animation", "mapping"],
  },
  //project start
  {
    shortTitle: "test",
    title: "Die Vier Letzten Dinge | Stuttgart",
    slug: "die-vier-letzten-dinge-stuttgart",
    imageURL:
      "https://lichtgst.uber.space/photos/projects/die-vier-letzten-dinge-stuttgart/dievierletztendinge-lichtgestalten-1.jpg",
    imageTitle: "testBild",
    galleryContent:[
      {
        type: "video",
        url: "https://vimeo.com/132700768",
      },
      {
        type: "image",
        url: "https://lichtgst.uber.space/photos/projects/die-vier-letzten-dinge-stuttgart/dievierletztendinge-lichtgestalten-1.jpg",
      },
      {
        type: "image",
        url: "https://lichtgst.uber.space/photos/projects/die-vier-letzten-dinge-stuttgart/dievierletztendinge-lichtgestalten-2.jpg",
      },
      {
        type: "image",
        url: "https://lichtgst.uber.space/photos/projects/die-vier-letzten-dinge-stuttgart/dievierletztendinge-lichtgestalten-3.jpg",
      },
      {
        type: "image",
        url: "https://lichtgst.uber.space/photos/projects/die-vier-letzten-dinge-stuttgart/dievierletztendinge-lichtgestalten-4.jpg",
      },
      {
        type: "image",
        url: "https://lichtgst.uber.space/photos/projects/die-vier-letzten-dinge-stuttgart/dievierletztendinge-lichtgestalten-5.jpg",
      },
      {
        type: "image",
        url: "https://lichtgst.uber.space/photos/projects/die-vier-letzten-dinge-stuttgart/dievierletztendinge-lichtgestalten-6.jpg",
      },
    ],
    descriptionEn: 
      "Silinee Damsa-Ard and Philip Roscher developed the piece 'Die Vier Letzten Dinge' as part of the Terrorisms International Theatre Festival in Stuttgart. Dealing with a protagonist who loses himself in a never ending clash of religious and social values, the piece tries to find answers for well known question: is there an objective truth? And why do different philosophies and mindsets claim superiority over contradicting or deviant opinions? Miodrag Nerandzic designed the modular stage, which evolves from a 2,20 m sized cube to a cathedral like wall. Throughout the piece, the protagonist locks himself into his own world. Lichtgestalten used this black canvas to work with the concept of perspective: if there’s only one perspective, but we view it from different angles, is it still the same perspective? Are we overchallenged if there are too many perspectives? How much information do we need to perceive something in its intended perspective?",
    descriptionDe:
      "Im Rahmen des 'Terrorism-Theater-Festival' am Staatstheater Stuttgart entwickelten Silinee Damsa-Ard und Philip Roscher das Stück 'Die Vier Letzten Dinge'. Miodrag Nerandzic konzipierte das Bühnenbild, das eine Transformation eines 2,20m großen Würfen zu den Wänden einer Kathedrale erlaubte. Lichtgestalten nutzten diese Leinwand, um das Spiel mit Perspektiven und dem Verlust ebendieser durch Videoprojektionen aufzugreifen.",
    info: [
      {
        slugCreditsEn: "Date",
        slugCreditsDe: "Datum",
        values: ["25. - 27.06.2015"],
      },
      {
        slugCreditsEn: "Client",
        slugCreditsDe: "Kunde",
        values: ["Staatstheater Stuttgart #https://www.staatstheater-stuttgart.de/"],
      },
      {
        slugCreditsEn: "Production, Concept & Animation",
        slugCreditsDe: "Produktion, Konzept & Animation",
        values: ["Lichtgestalten"],
      },
      {
        slugCreditsEn: "Documentation",
        slugCreditsDe: "Dokumentation",
        values: [ 
          "Video, photos 1 - 3 | Jan Potente #https://www.janpotente.de/",
          "Photos 3 - 5 | Anastasia Hermann #https://anastasiahermann.de/"
        ]
      },
    ],
    tags: ["artDirection", "animation", "mapping"],
  },
  //project start
  {
    shortTitle: "test",
    title: "THIS IS HOW WE DO IT | ABK",
    slug: "this-is-how-we-do-it-stuttgart",
    imageURL:
      "https://lichtgst.uber.space/photos/projects/this-is-how-we-do-it-stuttgart/this-is-how-we-do-it-lichtgestalten-2.jpg",
    imageTitle: "testBild",
    galleryContent:[
      {
        type: "video",
        url: "https://vimeo.com/117272620",
      },
      {
        type: "image",
        url: "https://lichtgst.uber.space/photos/projects/this-is-how-we-do-it-stuttgart/this-is-how-we-do-it-lichtgestalten-2.jpg",
      },
      {
        type: "image",
        url: "https://lichtgst.uber.space/photos/projects/this-is-how-we-do-it-stuttgart/this-is-how-we-do-it-lichtgestalten-3.jpg",
      },
      {
        type: "image",
        url: "https://lichtgst.uber.space/photos/projects/this-is-how-we-do-it-stuttgart/this-is-how-we-do-it-lichtgestalten-4.jpg",
      },
      {
        type: "image",
        url: "https://lichtgst.uber.space/photos/projects/this-is-how-we-do-it-stuttgart/this-is-how-we-do-it-lichtgestalten-5.jpg",
      },
      {
        type: "image",
        url: "https://lichtgst.uber.space/photos/projects/this-is-how-we-do-it-stuttgart/this-is-how-we-do-it-lichtgestalten-6.jpg",
      },
      {
        type: "image",
        url: "https://lichtgst.uber.space/photos/projects/this-is-how-we-do-it-stuttgart/this-is-how-we-do-it-lichtgestalten-8.jpg",
      },
    ],
    descriptionEn: 
      "To celebrate the 25th anniversary of the architecture department at the State Academy of Art and Design in Stuttgart, the international two-day conference „This is how we do it“ took place. The aim of the project was to give a lecture on the theme of architectural education in general and to discuss the contents. The focus was on regarding the State Academy to other kinds of universities, and on the question of free development.The two graphic designer Christian Nicolaus and Ute Mueller-Schloesser developed the title „This is how we do it“ as well as the relating concept and visualization of the event. The concept and animations for the projection mapping were developed by the Stuttgart based visual artists Lichtgestalten. The basic approach for the graphic design plays with the concept of ‚unfolding‘, particulary regarding the development of ideas and workflows in the context of different philosophies of architectural education. Eventually Christian Nicolaus and Ute Mueller-Schloesser found a way to combine the opposing phrases „This Is How We Do It“ and „This Is How They Do It“ on the cover of the invitation card with a special folding. The same folding structure was also used for the poster, so that the visible content depends on the perspective of the people passing by. The projection mapping itself was developed to further extend these ideas. Using light and shadow, a virtual light source floating in ‚front‘ of the installation revealed the contents of the poster. The installation consisted of one printed poster in the center and two blanc canvases on the left and right. As the projection was accompanying the talks, we decided to go for a subtle approach. Playing with light/shadow and printed/projected media allowed us to create a canvas to play with illusions and contrast, highlighting the structure without distracting from the content.",
    descriptionDe:
      "Zum 25. Jubiläum des Fachbereichs Architektur der Staatlichen Akademie der Bildenden Künste in Stuttgart entwarfen Christian Nicolaus und Ute Mueller-Schloesser unter dem Motto 'This is how we do it' ein Plakat, dessen Inhalt durch Faltung verändert werden konnte. Mit einem Videomapping bespielten wir dieses Plakat mit virtuellen Schatten und Lichtern sowie Negativbildern.",
    info: [
      {
        slugCreditsEn: "Date",
        slugCreditsDe: "Datum",
        values: ["05. - 06.12.2014"],
      },
      {
        slugCreditsEn: "Client",
        slugCreditsDe: "Kunde",
        values: ["Christian Nicolaus #http://christiannicolaus.de/"],
      },
      {
        slugCreditsEn: "Production & Animation",
        slugCreditsDe: "Produktion & Animation",
        values: ["Lichtgestalten"],
      },
      {
        slugCreditsEn: "Concept",
        slugCreditsDe: "Konzept",
        values: [
          "Christian Nicolaus #http://christiannicolaus.de/",
          "Lichtgestalten",
        ],
      },
      {
        slugCreditsEn: "Poster Design",
        slugCreditsDe: "Gestaltung Poster",
        values: [
          "Christian Nicolaus #http://christiannicolaus.de/",
          "Uter Müller-Schlösser",
        ],
      },
    ],
    tags: ["artDirection", "animation", "mapping"],
  },
  //project start
  {
    shortTitle: "test",
    title: "MS Dockville 2014 | Hamburg",
    slug: "ms-dockville-2014-hamburg",
    imageURL:
      "https://lichtgst.uber.space/photos/projects/ms-dockville-2014-hamburg/ms-dockville-2014-lichtgestalten-1.jpg",
    imageTitle: "testBild",
    galleryContent:[
      {
        type: "video",
        url: "https://vimeo.com/108559398",
        aspect: "1.7777778",
      },
      {
        type: "image",
        url: "https://lichtgst.uber.space/photos/projects/ms-dockville-2014-hamburg/ms-dockville-2014-lichtgestalten-1.jpg",
      },
      {
        type: "image",
        url: "https://lichtgst.uber.space/photos/projects/ms-dockville-2014-hamburg/ms-dockville-2014-lichtgestalten-2.jpg",
      },
      {
        type: "image",
        url: "https://lichtgst.uber.space/photos/projects/ms-dockville-2014-hamburg/ms-dockville-2014-lichtgestalten-3.jpg",
      },
      {
        type: "image",
        url: "https://lichtgst.uber.space/photos/projects/ms-dockville-2014-hamburg/ms-dockville-2014-lichtgestalten-4.jpg",
      },
      {
        type: "image",
        url: "https://lichtgst.uber.space/photos/projects/ms-dockville-2014-hamburg/ms-dockville-2014-lichtgestalten-5.jpg",
      },
      {
        type: "image",
        url: "https://lichtgst.uber.space/photos/projects/ms-dockville-2014-hamburg/ms-dockville-2014-lichtgestalten-7.jpg",
      },
      {
        type: "image",
        url: "https://lichtgst.uber.space/photos/projects/ms-dockville-2014-hamburg/ms-dockville-2014-lichtgestalten-8.jpg",
      },
      {
        type: "image",
        url: "https://lichtgst.uber.space/photos/projects/ms-dockville-2014-hamburg/ms-dockville-2014-lichtgestalten-9.jpg",
      },
    ],
    descriptionEn: 
      "The MS Dockville Festival in Hamburg invited us to do several installations over the weekend (15. – 17.08.2014). The large-scale projection on the “Rethespeicher” was without any doubt the eye-catcher of the five installations we did. The “Rethespeicher” has a size of around 170×75 meters, posing a perfect canvas for the artworks and projection mappings which were designed to fit to the architecture. The idea for the second largest projection was born on site, leaving only 2 days for the preparation and installation of two 10.000 Lumen projectors on top of a 30m high tower. The target was a 50m tall silo. The soft-edge projection was mapped to the convex shape to minimize perspective distortion. The animations, which could be seen perfectly from the crowd approaching the festival through the main entrance, included the line up of the MS Dockville 2014.",
    descriptionDe:
      "Für das MS Dockville Festival 2014 konzipierten und realisierten wir verschiedene audiovisuelle Installationen. Die Fassadenprojektion auf den 170 x 75 Meter großen Rethespeicher war dabei definitiv die auffälligste Installation. Die hierfür erstellten Motive wurden passgenau an die Architektur des Rethespeichers angepasst. Die Idee für die zweite Installation entstand erst vor Ort, wobei wir 2 Projektoren auf einem 30m hohen Turm installierten, um das benachbarte 50m hohe Silo mit Videoprojektionen per Soft-Edge-Mapping zu bespielen.",
    info: [
      {
        slugCreditsEn: "Date",
        slugCreditsDe: "Datum",
        values: ["15. - 17.08.2014"],
      },
      {
        slugCreditsEn: "Client",
        slugCreditsDe: "Kunde",
        values: ["Kopf & Steine GmbH #https://www.kopfundsteine.de/"],
      },
      {
        slugCreditsEn: "Production",
        slugCreditsDe: "Produktion",
        values: ["Lichtgestalten"],
      },
      {
        slugCreditsEn: "Design",
        slugCreditsDe: "Gestaltung",
        values: [
          "Fabian Friedrich #http://fabianfriedrich.com/",
          "Christian Nicolaus #http://christiannicolaus.de/",
          "Lichtgestalten",
        ],
      },
      {
        slugCreditsEn: "Animation",
        slugCreditsDe: "Animation",
        values: [
          "Fabian Friedrich #http://fabianfriedrich.com/",
          "Lichtgestalten",
        ],
      },
      {
        slugCreditsEn: "Documentation",
        slugCreditsDe: "Dokumentation",
        values: [
          "David Dollmann #http://daviddollmann.com/",
        ],
      },
    ],
    tags: ["artDirection", "animation", "mapping"],
  },
  //project start
  {
    shortTitle: "test",
    title: "SPEKTRUM 2014 | Hamburg",
    slug: "spektrum-2014-hamburg",
    imageURL:
      "https://lichtgst.uber.space/photos/projects/spektrum-2014-hamburg/spektrum-2014-lichtgestalten-1.jpg",
    imageTitle: "testBild",
    galleryContent:[
      {
        type: "video",
        url: "https://vimeo.com/110302078",
        aspect: "1.7777778",
      },
      {
        type: "image",
        url: "https://lichtgst.uber.space/photos/projects/spektrum-2014-hamburg/spektrum-2014-lichtgestalten-1.jpg",
      },
    ],
    descriptionEn: 
      "On the 2nd of August the SPEKTRUM Festival in Hamburg took place. It’s motto „Momentaufnahme Beatkultur“ defines an aim to show what’s going on in all the different genres of beat driven music inside and around the umbrella term of „Hip Hop“. We got the offer to illuminate a wall of 2×3 40′ cargo containers which seperates two stages. To meet the cutting edge motto of the SPEKTRUM festival, we didn’t want to go for classic fullscale projections, but rather give the audience the possibility to scan the surface with an imaginery light for it’s contents. The subtle projection was driven by a tracked pendulum, controlling a 3D engine to generate realtime lights (driven by the pendulum), shadows and reflections of pre-animated objects in front of the container wall.",
    descriptionDe:
      "Am 02. August fand das SPEKTRUM Festival in Hamburg statt, das unter dem Motto 'Momentaufnahme Beatkultur' den verschiedenen Strömungen innerhalb der Beatkultur eine Plattform bietet. Dafür wurden lichtgestalten beauftragt, eine Schallschutzwand aus 2x3 40'HC Containern zu bespielen. Mit einer Pendelinstallation, die es den Besuchern des Festivals erlaubte, die Projektionsinhalte interaktiv zu steueren, konnte z.B. eine virtuelle Lichtquelle bewegt werden und das Line-Up des Festivals auf spielerische Art und Weise über eine 3D-Engine sichtbar gemacht werden.",
    info: [
      {
        slugCreditsEn: "Date",
        slugCreditsDe: "Datum",
        values: ["02.08.2014"],
      },
      {
        slugCreditsEn: "Client",
        slugCreditsDe: "Kunde",
        values: ["Kopf & Steine GmbH #https://www.kopfundsteine.de/"],
      },
      {
        slugCreditsEn: "Concept, Production, Animation, Mapping",
        slugCreditsDe: "Konzept, Produktion, Animation & Mapping",
        values: ["Lichtgestalten"],
      },
      {
        slugCreditsEn: "Documentation",
        slugCreditsDe: "Dokumentation",
        values: ["Jan Wittekindt"],
      },
    ],
    tags: ["realtime", "animation", "mapping"],
  },
  //project start
  {
    shortTitle: "test",
    title: "Lange Nacht der Museen 2014 | Böblingen",
    slug: "lange-nacht-der-museen-2014-boeblingen",
    imageURL:
      "https://lichtgst.uber.space/photos/projects/lange-nacht-der-museen-2014-boeblingen/lange-nacht-der-museen-boeblingen-lichtgestalten-2014-1.jpg",
    imageTitle: "testBild",
    galleryContent:[
      {
        type: "image",
        url: "https://lichtgst.uber.space/photos/projects/lange-nacht-der-museen-2014-boeblingen/lange-nacht-der-museen-boeblingen-lichtgestalten-2014-1.jpg",
      },
      {
        type: "image",
        url: "https://lichtgst.uber.space/photos/projects/lange-nacht-der-museen-2014-boeblingen/lange-nacht-der-museen-boeblingen-lichtgestalten-2014-2.jpg",
      },
      {
        type: "image",
        url: "https://lichtgst.uber.space/photos/projects/lange-nacht-der-museen-2014-boeblingen/lange-nacht-der-museen-boeblingen-lichtgestalten-2014-3.jpg",
      },
      {
        type: "image",
        url: "https://lichtgst.uber.space/photos/projects/lange-nacht-der-museen-2014-boeblingen/lange-nacht-der-museen-boeblingen-lichtgestalten-2014-5.jpg",
      },
      {
        type: "image",
        url: "https://lichtgst.uber.space/photos/projects/lange-nacht-der-museen-2014-boeblingen/lange-nacht-der-museen-boeblingen-lichtgestalten-2014-4.jpg",
      },
      {
        type: "image",
        url: "https://lichtgst.uber.space/photos/projects/lange-nacht-der-museen-2014-boeblingen/lange-nacht-der-museen-boeblingen-lichtgestalten-2014-6.jpg",
      },
      {
        type: "image",
        url: "https://lichtgst.uber.space/photos/projects/lange-nacht-der-museen-2014-boeblingen/lange-nacht-der-museen-boeblingen-lichtgestalten-2014-7.jpg",
      },
    ],
    descriptionEn: 
      "As part of the „Lange Nacht der Museen“ we illuminated the „St. Dionysius“ church in Böblingen. Content-wise we went for a mixed approach, as we wanted to be able to cover the total period of the event (7 hours) with interesting content: some animations played with the architecture, proposing different settings and transforming the central place into other scenes like a lighthouse, the launch of a rocket or highrises. In between those „scripted“ animations we prepared loose elements based on abstract geometry and the structure of the building. Thus we could jam and improvise on this large canvas or tell short storys with the scripted animations.",
    descriptionDe:
      "Die Kirche 'St. Dionysius' in Böblingen war unsere Leinwand für die 'Lange Nacht der Museen 2014' in Böblingen. Mit einer Mischung aus vorgerenderten Videos und generativen, improvisierten Animationen konnten wir 7 Stunden mit abwechslungsreichen Projektionen auf der Fassade der Kirche gestalten. So wurde die Kirche u.a. in einen Leuchtturm, einen Raketenstart oder ein Hochhaus verwandelt.",
    info: [
      {
        slugCreditsEn: "Date",
        slugCreditsDe: "Datum",
        values: ["15.11.2014"],
      },
      {
        slugCreditsEn: "Client",
        slugCreditsDe: "Kunde",
        values: ["Outer Rim Stuttgart #https://outerrimblog.wordpress.com/"],
      },
      {
        slugCreditsEn: "Concept, Animation & Mapping",
        slugCreditsDe: "Konzept, Animation & Mapping",
        values: ["Lichtgestalten"],
      },
    ],
    tags: ["animation", "mapping"],
  },
  //project start
  {
    shortTitle: "test",
    title: "Beyond Festival | ZKM Karlsruhe",
    slug: "3d-symposium-beyond-festival-zkm-karlsruhe",
    imageURL:
      "https://lichtgst.uber.space/photos/projects/3d-symposium-beyond-festival-zkm-karlsruhe/zkm-beyond-festival-lichtgestalten-3.jpg",
    imageTitle: "testBild",
    galleryContent:[
      {
        type: "image",
        url: "https://lichtgst.uber.space/photos/projects/3d-symposium-beyond-festival-zkm-karlsruhe/zkm-beyond-festival-lichtgestalten-3.jpg",
      },
      {
        type: "image",
        url: "https://lichtgst.uber.space/photos/projects/3d-symposium-beyond-festival-zkm-karlsruhe/zkm-beyond-festival-lichtgestalten-2.jpg",
      },
      {
        type: "image",
        url: "https://lichtgst.uber.space/photos/projects/3d-symposium-beyond-festival-zkm-karlsruhe/zkm-beyond-festival-lichtgestalten-1.jpg",
      },
      {
        type: "image",
        url: "https://lichtgst.uber.space/photos/projects/3d-symposium-beyond-festival-zkm-karlsruhe/zkm-beyond-festival-lichtgestalten-4.jpg",
      },
    ],
    descriptionEn: 
      "The Beyond Symposium explores and discusses the use and influence of new technologies and experimental art forms. We were invited to set the atmosphere for the come together, there a relaxed room for conversations met the dancefloor of an aftershow party. Combining a translucent material with projections, we achieved a set of vivid clouds with the ability to explore a broad array from ambient illumination to intense thunderstorms.",
    descriptionDe:
      "Das Beyond Symposium am ZKM Karlsruhe beschäftigt sich mit neuen Technologien und experimentellen Kunstformen. Mit wolkenähnlichen Gebilden aus halbtransparenten Materalien konnten wir durch verschiedene Videoprojektionen sowohl die passende Atmosphäre für entspannte Gespräche sowie die anschließende After-Show-Party schaffen, so z.B. durch langsame organische Animationen als Grundbeleuchtung bis hin zu gewitterähnlichen Stroboskopeffekten.",
    info: [
      {
        slugCreditsEn: "Date",
        slugCreditsDe: "Datum",
        values: ["11.10.2014"],
      },
      {
        slugCreditsEn: "Client",
        slugCreditsDe: "Kunde",
        values: ["ZKM Karlsruhe #https://zkm.de"],
      },
      {
        slugCreditsEn: "Concept, Production & Mapping",
        slugCreditsDe: "Konzept, Produktion & Mapping",
        values: ["Lichtgestalten"],
      },
    ],
    tags: ["mapping", "installation"],
  },
  //project start
  {
    shortTitle: "test",
    title: "Überblume | Marienplatzfest",
    slug: "ueberblume-marienplatzfest-stuttgart",
    imageURL:
      "https://lichtgst.uber.space/photos/projects/ueberblume-marienplatzfest-stuttgart/ueberblume-marienplatztfest-2015-lichtgestalten-4.jpg",
    imageTitle: "testBild",
    galleryContent:[
      {
        type: "image",
        url: "https://lichtgst.uber.space/photos/projects/ueberblume-marienplatzfest-stuttgart/ueberblume-marienplatztfest-2015-lichtgestalten-4.jpg",
      },
      {
        type: "image",
        url: "https://lichtgst.uber.space/photos/projects/ueberblume-marienplatzfest-stuttgart/ueberblume-marienplatzfest-2014-lichtgestalten-2.jpg",
      },
      {
        type: "image",
        url: "https://lichtgst.uber.space/photos/projects/ueberblume-marienplatzfest-stuttgart/ueberblume-marienplatzfest-2014-lichtgestalten-1.jpg",
      },
      {
        type: "image",
        url: "https://lichtgst.uber.space/photos/projects/ueberblume-marienplatzfest-stuttgart/ueberblume-marienplatztfest-2015-lichtgestalten-6.jpg",
      },
      {
        type: "image",
        url: "https://lichtgst.uber.space/photos/projects/ueberblume-marienplatzfest-stuttgart/ueberblume-marienplatztfest-2015-lichtgestalten-1.jpg",
      },
      {
        type: "image",
        url: "https://lichtgst.uber.space/photos/projects/ueberblume-marienplatzfest-stuttgart/ueberblume-marienplatztfest-2015-lichtgestalten-3.jpg",
      },
      {
        type: "image",
        url: "https://lichtgst.uber.space/photos/projects/ueberblume-marienplatzfest-stuttgart/ueberblume-marienplatztfest-2015-lichtgestalten-7.jpg",
      },
      {
        type: "image",
        url: "https://lichtgst.uber.space/photos/projects/ueberblume-marienplatzfest-stuttgart/ueberblume-marienplatztfest-2015-lichtgestalten-5.jpg",
      },
    ],
    descriptionEn: 
      "The installation 'Überblume' was a set of 20 artificial flowers, which served as shadow casters at daytime and as artificial suns at night. Their translucent blossom was sprayed with different colours, which revealed a variety of gradients depending on the perspective of the spectator. Because of their flexible straw they waved in the wind and softened the rigid atmosphere of the surrounding architecture. At night, 3 projectors illuminated the blossoms, highlighting the complex three dimensional structure of the mesh.",
    descriptionDe:
      "Die Installation 'Überblume' bestand aus 20 künstlichen Blumen, die bei Tag als Schattenspender und bei Nacht als Leuchtobjekte den Marienplatz in Stuttgart verzierten. Die aus halbtransparentem Material bestehenden Blüten wurden mit Farbverläufen angesprüht, wodurch sie je nach Blickwinkel unterschiedliche Farbeindrücke hinterließen. Durch ihren elastischen 'Halm' reagierten sie sanft auf Windstöße und verpassten dem urbanen, steinernen Marienplatz eine organische Komponente.",
    info: [
      {
        slugCreditsEn: "Date",
        slugCreditsDe: "Datum",
        values: [" 03. – 06.07.2014 & 02. – 05.07.2015"],
      },
      {
        slugCreditsEn: "Client",
        slugCreditsDe: "Kunde",
        values: ["Marienplatzfest"],
      },
      {
        slugCreditsEn: "Concept, Production & Installation",
        slugCreditsDe: "Konzept, Produktion & Installation",
        values: ["Lichtgestalten"],
      },
    ],
    tags: ["installation"],
  },
  //project start
  {
    shortTitle: "test",
    title: "XXO | Step Sequencer",
    slug: "xxo-step-sequencer-stuttgart",
    imageURL:
      "https://lichtgst.uber.space/photos/projects/xxo-step-sequencer-stuttgart/xxo-interactive-step-sequencer-lichtgestalten-1.jpg",
    imageTitle: "testBild",
    galleryContent:[
      {
        type: "video",
        url: "https://vimeo.com/101651264",
        aspect: "1.77777778",
      },
      {
        type: "image",
        url: "https://lichtgst.uber.space/photos/projects/xxo-step-sequencer-stuttgart/xxo-interactive-step-sequencer-lichtgestalten-1.jpg",
      },
    ],
    descriptionEn: 
      "Interactive audio/visual step sequencer, programmed and installed by lichtgestalten. By placing paper objects (in this case our business cards) on the projected matrix, the spectator is able to programm rhythmic patterns. Those patterns can trigger any sound you want via MIDI. Set Up: projector / web cam / ableton live / max msp",
    descriptionDe:
      "'XXO' ist ein interaktiver, audiovisueller Step Sequencer, der von lichtgestalten konzipiert und programmiert wurde. Das Verschieben von Papierobjekten in einem projizierten Raster ermöglicht es, Rhythmen oder Melodien zu programmieren. Über MIDI werden anschließen Klänge ausgelöst. Durch die Größe des Bedienfelds des Step Sequencers erfordert 'XXO' eine Art Performance des Interagierenden.",
    info: [
      {
        slugCreditsEn: "Date",
        slugCreditsDe: "Datum",
        values: ["12.06.2015 & 05.2015"],
      },
      {
        slugCreditsEn: "Concept, Programming & Installation",
        slugCreditsDe: "Konzept, Programmierung & Installation",
        values: ["Lichtgestalten"],
      },
    ],
    tags: ["realtime", "installation"],
  },
  //project start
  {
    shortTitle: "test",
    title: "Eau Rouge – Golden Nights",
    slug: "eau-rouge-golden-nights-stuttgart",
    imageURL:
      "https://lichtgst.uber.space/photos/projects/eau-rouge-golden-nights-stuttgart/GOLDEN-NIGHTS-02-LICHTGESTALTEN.jpg",
    imageTitle: "testBild",
    galleryContent:[
      {
        type: "video",
        url: "https://vimeo.com/107140667",
        aspect: "2",
      },
      {
        type: "image",
        url: "https://lichtgst.uber.space/photos/projects/eau-rouge-golden-nights-stuttgart/GOLDEN-NIGHTS-02-LICHTGESTALTEN.jpg",
      },
      {
        type: "image",
        url: "https://lichtgst.uber.space/photos/projects/eau-rouge-golden-nights-stuttgart/GOLDEN-NIGHTS-03-LICHTGESTALTEN.jpg",
      },
      {
        type: "image",
        url: "https://lichtgst.uber.space/photos/projects/eau-rouge-golden-nights-stuttgart/GOLDEN-NIGHTS-04-LICHTGESTALTEN.jpg",
      },
      {
        type: "image",
        url: "https://lichtgst.uber.space/photos/projects/eau-rouge-golden-nights-stuttgart/GOLDEN-NIGHTS-01-LICHTGESTALTEN.jpg",
      },
    ],
    descriptionEn: 
      "We were part of the production cast for the music video to „Golden Nights“ by Eau Rouge. The goal of our projections was to reveal the emotions of the lyrical self, in this case the singer Jonas. As the lyrics are about euphoric moments (represented by gold), we switched Jonas‘ black and cold „bloodflow“ into a golden stream. Later he had particles of endorphin flying all over his body. We animated and mapped the FullHD content onto Jonas‘ body and advised the production team with technical details.",
    descriptionDe:
      "Für das Musikvideo der Band 'Eau Rouge' setzten wir ein Videomapping auf einen Körper um. Die Projektionen arbeiteten dabei mit einer Abstraktion des Begriffs 'Euphorie', wobei sowohl schwarze und goldene Flüssigkeitsströme als auch Partikelexplosionen zum Einsatz kamen.",
    info: [
      {
        slugCreditsEn: "Date",
        slugCreditsDe: "Datum",
        values: ["01.12.2014"],
      },
      {
        slugCreditsEn: "Client",
        slugCreditsDe: "Kunde",
        values: ["Eau Rouge"],
      },
      {
        slugCreditsEn: "Production",
        slugCreditsDe: "Produktion",
        values: [
          "Till Beckert #https://www.tillbeckert.de/",
          "Daniel Schinow",
        ],
      },
      {
        slugCreditsEn: "Concept, Animation & Mapping",
        slugCreditsDe: "Konzept, Animation & Mapping",
        values: ["Lichtgestalten"],
      },
    ],
    tags: ["animation", "mapping"],
  },
  //project start
  {
    shortTitle: "test",
    title: "Square Swarm",
    slug: "square-swarm-rocker33-stuttgart",
    imageURL:
      "https://lichtgst.uber.space/photos/projects/square-swarm-rocker33-stuttgart/square-swarm-rocker-lichtgestalten-1.jpg",
    imageTitle: "testBild",
    galleryContent:[
      {
        type: "image",
        url: "https://lichtgst.uber.space/photos/projects/square-swarm-rocker33-stuttgart/square-swarm-rocker-lichtgestalten-1.jpg",
      },
      {
        type: "image",
        url: "https://lichtgst.uber.space/photos/projects/square-swarm-rocker33-stuttgart/square-swarm-rocker-lichtgestalten-2.jpg",
      },
      {
        type: "image",
        url: "https://lichtgst.uber.space/photos/projects/square-swarm-rocker33-stuttgart/square-swarm-rocker-lichtgestalten-3.jpg",
      },
      {
        type: "image",
        url: "https://lichtgst.uber.space/photos/projects/square-swarm-rocker33-stuttgart/square-swarm-rocker-lichtgestalten-4.jpg",
      },
      {
        type: "image",
        url: "https://lichtgst.uber.space/photos/projects/square-swarm-rocker33-stuttgart/swarm-wilhelmspalais-stuttgart-lichtgestalten-1.jpg",
      },
      {
        type: "image",
        url: "https://lichtgst.uber.space/photos/projects/square-swarm-rocker33-stuttgart/swarm-wilhelmspalais-stuttgart-lichtgestalten-2.jpg",
      },
    ],
    descriptionEn: 
      "The square swarm was a live VJing – projection mapping onto more than 120 surfaces (Dimensions: DIN A6, A5, A4). It’s diffuse arrangement emits a very organic feel, which is supported by irregular visual patterns rather than linear patterns. Temporarily Installed at Rocker33, Stuttgart and Wilhelmspalais, Stuttgart.",
    descriptionDe:
      "'Square Swarm' war eine live VJing - Projection Mapping Installation mit mehr als 120 Papierflächen in den Größen DIN A6, A5 und A4. Durch die organische Anordnung der Papierflächen und das präzise Mapping der Videoprojektion ergaben sich komplexe visuelle Eindrücke. 'Square Swarm' wurde im Rocker33 und dem Wilhelmspalais in Stuttgart installiert.",
    info: [
      {
        slugCreditsEn: "Date",
        slugCreditsDe: "Datum",
        values: ["08.03.2013"],
      },
      {
        slugCreditsEn: "Concept, Animation & Live",
        slugCreditsDe: "Konzept, Animation & Live",
        values: ["Lichtgestalten"],
      },
    ],
    tags: ["mapping"],
  },
];
