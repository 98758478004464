import React, { useState } from "react";
import { Link } from "gatsby";
import { useLocation } from "@reach/router"

import { filterState } from "./atoms/filter"
import { useRecoilState } from "recoil"

import Logo from "../static/lchtgst_logo_only_white_paths.svg";

import { workContent } from "../content/workContent";
//import useDarkMode from "use-dark-mode";

const Work = () => {

  const location = useLocation();
  const [currentFilter, setFilter] = useRecoilState(filterState);
  //console.log("current" + currentFilter);
  const [currentTags, setTags] = useState([]);
  
  const darkMode = true;

  const srcSetSizeXS = 480;
  const srcSetSizeS = 600;
  const srcSetSizeM = 960;
  const srcSetSizeL = 1400;
  const srcSetSizeXL = 1920;
  const srcSetSizeXXL = 2560;
  
  return (
    <div>
      <div className="portfolio">
        <div>
          <div className="row imagetiles">
            {workContent
              .filter((item) =>
                //checks if there is a filter for the projects to show or not
                currentFilter ? item.tags.includes(currentFilter) : item
              )
              .map((project, index) => {
                return (
                  <div
                    key={index}
                    id={project.slug}
                    className="col-sm-12 col-xs-24 col-portfolio"
                  >
                    <Link
                      to = {"projects/" + `${project.slug}`}
                    >
                      <div className="row overlay workTitle">
                        <div className="projectLogo">
                          <Logo className="workLogo" />
                        </div>
                        <h1 className="project-hover-title">
                          {`${project.title}`}
                        </h1>
                      </div>
                      <img
                        //src={`${project.imageURL.substr(0, project.imageURL.lastIndexOf("."))}` + '_' + `${srcSetSizeM}` + `${project.imageURL.substr(project.imageURL.lastIndexOf("."))}`}
                        sizes="@media (max-width: 576px) 100vw, 50vw"
                        srcSet={
                          `${project.imageURL.substr(0, project.imageURL.lastIndexOf("."))}` + '_' + `${srcSetSizeXXL}` + `${project.imageURL.substr(project.imageURL.lastIndexOf("."))}` + ' ' + `${srcSetSizeXXL}` + "w, " +
                          `${project.imageURL.substr(0, project.imageURL.lastIndexOf("."))}` + '_' + `${srcSetSizeXL}` + `${project.imageURL.substr(project.imageURL.lastIndexOf("."))}` + ' ' + `${srcSetSizeXL}` + "w, " +
                          `${project.imageURL.substr(0, project.imageURL.lastIndexOf("."))}` + '_' + `${srcSetSizeL}` + `${project.imageURL.substr(project.imageURL.lastIndexOf("."))}` + ' ' + `${srcSetSizeL}` + "w, " +
                          `${project.imageURL.substr(0, project.imageURL.lastIndexOf("."))}` + '_' + `${srcSetSizeM}` + `${project.imageURL.substr(project.imageURL.lastIndexOf("."))}` + ' ' + `${srcSetSizeM}` + "w, " +
                          `${project.imageURL.substr(0, project.imageURL.lastIndexOf("."))}` + '_' + `${srcSetSizeS}` + `${project.imageURL.substr(project.imageURL.lastIndexOf("."))}` + ' ' + `${srcSetSizeS}` + "w, " +
                          `${project.imageURL.substr(0, project.imageURL.lastIndexOf("."))}` + '_' + `${srcSetSizeXS}` + `${project.imageURL.substr(project.imageURL.lastIndexOf("."))}` + ' ' + `${srcSetSizeXS}` + "w"
                        }
                        className="img-fluid portfolio-cover"
                        title={`${project.title}`}
                        alt={`${project.title}`}
                        loading={index < 4 ? "eager" : "lazy"} //load first 4 projects right away, the rest lazy
                        width="960"
                        height="540"
                        onMouseEnter={
                          () => setTags(project.tags)
                          //console.log("mouseenter", project.title)
                        }
                        onMouseLeave={() => setTags([])}
                        //console.log("mouseleave")}
                      />
                    </Link>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
      <footer className="fixed-bottom">
        <div className="container-fluid footerContainer">
          <ul className="list-group list-group-horizontal">
            <li
              className="list-group-item work-category flex-fill"
              onClick={() =>
                  {
                    currentFilter === "artDirection"
                    ? setFilter("")
                    : setFilter("artDirection");
                    window.scrollTo({top:0, behavior: "auto"})
                  }
              }
            >
              <div
                className="footer-item"
                style={
                  currentFilter === "artDirection" ||
                  currentTags.includes("artDirection")
                    ? darkMode
                      ? { color: "#000", backgroundColor: "#fff" }
                      : { color: "#fff", backgroundColor: "#000" }
                    : null
                }
              >
                art direction
              </div>
            </li>
            <li
              className="list-group-item work-category flex-fill"
              onClick={() =>
                {
                  currentFilter === "animation"
                  ? setFilter("")
                  : setFilter("animation");
                  window.scrollTo({top:0, behavior: "auto"})
                }
              }
            >
              <div
                className="footer-item"
                style={
                  currentFilter === "animation" ||
                  currentTags.includes("animation")
                    ? darkMode
                      ? { color: "#000", backgroundColor: "#fff" }
                      : { color: "#fff", backgroundColor: "#000" }
                    : null
                }
              >
                animation
              </div>
            </li>
            <li
              className="list-group-item work-category flex-fill"
              onClick={() =>
                {
                  currentFilter === "mapping"
                  ? setFilter("")
                  : setFilter("mapping");
                  window.scrollTo({top:0, behavior: "auto"})
                }
              }
            >
              <div
                className="footer-item"
                style={
                  currentFilter === "mapping" || currentTags.includes("mapping")
                    ? darkMode
                      ? { color: "#000", backgroundColor: "#fff" }
                      : { color: "#fff", backgroundColor: "#000" }
                    : null
                }
              >
                mapping
              </div>
            </li>
            <li
              className="list-group-item work-category flex-fill"
              onClick={() =>
                {  
                  currentFilter === "realtime"
                  ? setFilter("")
                  : setFilter("realtime");
                  window.scrollTo({top:0, behavior: "auto"})
                }
              }
            >
              <div
                className="footer-item"
                style={
                  currentFilter === "realtime" ||
                  currentTags.includes("realtime")
                    ? darkMode
                      ? { color: "#000", backgroundColor: "#fff" }
                      : { color: "#fff", backgroundColor: "#000" }
                    : null
                }
              >
                realtime
              </div>
            </li>
            <li
              className="list-group-item work-category flex-fill"
              onClick={() =>
                {  
                  currentFilter === "installation"
                  ? setFilter("")
                  : setFilter("installation");
                  window.scrollTo({top:0, behavior: "auto"})
                }
              }
            >
              <div
                className="footer-item"
                style={
                  currentFilter === "installation" ||
                  currentTags.includes("installation")
                    ? darkMode
                      ? { color: "#000", backgroundColor: "#fff" }
                      : { color: "#fff", backgroundColor: "#000" }
                    : null
                }
              >
                installation
              </div>
            </li>
          </ul>
        </div>
      </footer>
    </div>
  );
};

export default Work;
