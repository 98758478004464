import React from "react";

import Layout from "../components/layout";
import Work from "../components/work";
import SEO from "../components/seo";

import { injectIntl, FormattedMessage } from "gatsby-plugin-react-intl";

const IndexPage = ({intl}) => (
      <Layout>
        <SEO title={intl.formatMessage({ id: "titleWork" })} lang={intl.locale} />
        <Work></Work>
      </Layout>
)

export default injectIntl(IndexPage)
